import React, { useState, useEffect } from "react";
import "./RequestDetailsByCustmer.css";
import { Avatar, Card, Col, Row, Typography } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { ReactComponent as TableShopIcon } from "../../assets/icons/TableShopicon.svg";
import { baseUrl } from "../../commonUtils/commonUtils";
import RequestDetailsByCustmerModal from "../Modal/RequestDetailsByCustmerModal/RequestDetailsByCustmerModal";
const { Title, Text } = Typography;

const RequestDetailsByCustmer = (props) => {
  const { listData } = props;
  console.log(
    "🚀 ~ RequestDetailsByCustmer ~ listData:",
    listData?.auto_generated
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    // Calculate totalCount whenever listData changes
    if (listData?.quote_requesteditems) {
      let count = 0;
      Object.keys(listData.quote_requesteditems).forEach((vendorKey) => {
        count += listData.quote_requesteditems[vendorKey].reduce(
          (acc, item) => acc + item.qty,
          0
        );
      });
      setTotalCount(count);
    }
  }, [listData]);
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const productCheck =
    listData && listData?.product_details
      ? Object.keys(listData?.product_details)?.length
      : 0;
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handlePdfDownload = (pdfURL) => {
    const pdfUrl = `${baseUrl}${pdfURL}`;
    window.open(pdfUrl, "_blank");
  };
  const colorSizeList = listData?.product_details?.variant_info || [];

  const mobSkuDetails = () => {
    if (colorSizeList?.length === 0) {
      return [];
    }
    let detailArray = [];
    colorSizeList?.map((item) => {
      for (let key in item) {
        detailArray.push({ label: key, value: item[key] });
      }
      return null;
    });
    return detailArray;
  };
  return (
    <>
      {!listData?.auto_generated && (
        <Card
          className="request-customer-card"
          style={{
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
          }}
        >
          <Col className="tittle-card">
            <Title level={3} style={{ margin: 0 }}>
              Request details by the customer
            </Title>
          </Col>
          {productCheck === 0 ? (
            <Row
              style={{
                display: "flex",
                gap: "24px",
                padding: "0 24px 24px 24px",
              }}
            >
              {listData?.rfq_file && (
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "52px",
                    padding: " 14px 16px",
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                    color: " #2973f0",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <FileOutlined />
                    <Text style={{ color: " #2973f0" }}>
                      {/* {rfq_file?.toUpperCase()} */}
                    </Text>
                  </Col>
                  <Text
                    style={{ color: " #2973f0", cursor: "pointer" }}
                    onClick={() => handlePdfDownload(listData?.rfq_file)}
                  >
                    View
                  </Text>
                </Col>
              )}
              {listData?.rfq_remarks && (
                <Col>
                  <Title level={5} style={{ margin: "0 0 1rem 0" }}>
                    COMMENTS
                  </Title>
                  {listData?.rfq_remarks && (
                    <>
                      {listData?.rfq_remarks.length < 250 ? (
                        <span>{listData?.rfq_remarks}</span>
                      ) : (
                        <>
                          {isExpanded
                            ? listData?.rfq_remarks
                            : `${listData?.rfq_remarks.slice(0, 250)}...`}
                          <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={handleToggleExpand}
                          >
                            {isExpanded ? " View less" : " View more"}
                          </span>
                        </>
                      )}
                    </>
                  )}

                  {/* <Text style={{}}>{listData?.rfq_remarks}</Text> */}
                </Col>
              )}
            </Row>
          ) : (
            <>
              <Col className="request-customer-component-1">
                <Col className="component-avatar">
                  <Avatar
                    shape="square"
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "16px",
                    }}
                    size={60}
                    icon={<TableShopIcon style={{ marginTop: "12px" }} />}
                  />
                  <Col className="component-text-container">
                    <Text className="text-1">
                      {listData?.product_details?.vendor_name}
                    </Text>
                    <Text className="text-2">
                      BMPID: {listData?.product_details?.bmp_id}
                    </Text>
                  </Col>
                </Col>
                <Text className="text-3" onClick={() => handleOpenModal()}>
                  View seller details
                </Text>
              </Col>
              <Col className="request-customer-component-2">
                <Col className="component-avatar-2">
                  <Avatar
                    shape="square"
                    style={{
                      backgroundColor: "#f3f2ef",
                      borderRadius: "16px",
                    }}
                    size={80}
                    src={`${baseUrl}${listData?.product_details?.image}`}
                    icon={<TableShopIcon style={{ marginTop: "12px" }} />}
                  />
                  <Col className="component-text-container-2">
                    <Text className="text-heading">
                      {listData?.product_details?.product_name}
                    </Text>
                    <Text className="text-2" style={{ marginTop: "10px" }}>
                      MOBSKU: {listData?.product_details?.mob_sku}
                    </Text>
                    {mobSkuDetails()?.map((item) => {
                      return (
                        <>
                          <Text className="text-color">
                            {item?.label}:{" "}
                            <Text className="text-color-in">{item?.value}</Text>
                          </Text>
                        </>
                      );
                    })}
                  </Col>
                </Col>
                <Text className="text-color">
                  Qty:{" "}
                  <Text className="text-color-in">
                    {listData?.product_details?.quantity}
                  </Text>
                </Text>
              </Col>
              {listData?.rfq_remarks && (
                <Col className="comment-container">
                  <Title level={5} style={{ margin: "0 0 1rem 0" }}>
                    COMMENTS
                  </Title>
                  {listData?.rfq_remarks && (
                    <>
                      {listData?.rfq_remarks.length < 250 ? (
                        <span>{listData?.rfq_remarks}</span>
                      ) : (
                        <>
                          {isExpanded
                            ? listData?.rfq_remarks
                            : `${listData?.rfq_remarks.slice(0, 250)}...`}
                          <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={handleToggleExpand}
                          >
                            {isExpanded ? " View less" : " View more"}
                          </span>
                        </>
                      )}
                    </>
                  )}

                  {/* <Text style={{}}>{listData?.rfq_remarks}</Text> */}
                </Col>
              )}

              <RequestDetailsByCustmerModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                listData={listData}
              />
            </>
          )}
        </Card>
      )}

      {listData?.auto_generated &&
        listData?.quote_requesteditems &&
        Object.keys(listData.quote_requesteditems).length > 0 && (
          
          <Card
            className="rfq-customer-card"
            style={{
              boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
            }}
          >
            <Col className="tittle-card" style={{borderBottom:"1px solid #dedede"}}>
              <Title level={3} style={{ margin: 0 }}>
                {totalCount} items <span style={{ fontWeight: "400",fontSize:"16px" }}> (
                {Object.keys(listData?.quote_requesteditems).length} suborder
                request)</span>
              </Title>
            </Col>
            {listData?.rfq_remarks && (
            <Col className="comment-container" style={{marginTop:"12px"}}> 
              <Title level={5} style={{ margin: "0 0 2rem 0" }}>
                COMMENTS
              </Title>
              {listData?.rfq_remarks && (
                <>
                  {listData?.rfq_remarks.length < 250 ? (
                    <span>{listData?.rfq_remarks}</span>
                  ) : (
                    <>
                      {isExpanded
                        ? listData?.rfq_remarks
                        : `${listData?.rfq_remarks.slice(0, 250)}...`}
                      <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={handleToggleExpand}
                      >
                        {isExpanded ? " View less" : " View more"}
                      </span>
                    </>
                  )}
                </>
              )}

              {/* <Text style={{}}>{listData?.rfq_remarks}</Text> */}
            </Col>
          )}
            <Col className="quote-requested-items">
              {Object.keys(listData.quote_requesteditems).map((vendorKey) => {
                return (
                  <Col key={vendorKey} className="vendor-section ">
                    <Col className="rfq-heading-bmp">
                      <Text className="text-heading-rfqquote">
                        Mob partner: {vendorKey}
                      </Text>
                    </Col>
                    {listData.quote_requesteditems[vendorKey].map(
                      (item, index) => {
                        return (
                          <Row
                            gutter={[16, 16]}
                            key={index}
                            className="quote-item d-flex"
                          >
                            <Col span={20}>
                              {/* <img src={item.product_image} alt={item.product_name} style={{ width: '50px', height: '50px', marginRight: '10px' }} /> */}
                              <Text>
                                {item.product_name}
                                <br />
                                <span className="text-lightfont-rfqquote">
                                  MOBSKU:{item.mob_sku}{" "}
                                </span>
                              </Text>
                            </Col>
                            <Col className="text-right" span={4}>
                              <Text>
                                <span className="text-lightfont-rfqquote">
                                  Qty:{" "}
                                </span>
                                <br />
                                <span>{item.qty}</span>
                              </Text>
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </Col>
                );
              })}
            </Col>
          </Card>
        )}
    </>
  );
};

export default RequestDetailsByCustmer;
