import React, {
  useEffect,
  // useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";

import { ReactComponent as EditIcon } from "../../../assets/icons/pencil.svg";
import { ReactComponent as DuplicateIcon } from "../../../assets/icons/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file-copy.svg";

import { MoreOutlined } from "@ant-design/icons";
import DropdownComponent from "./DropdownComponent";
import { rfqListDataSelctor } from "../../../redux/slices/rfqList/selector";
import { useDispatch, useSelector } from "react-redux";
import {
  colorMapping,
  dateFormat,
  // string_dot,
} from "../../../commonFuntions/CommonUtilFunctions";
import RFQquoteModal from "../../../components/Modal/RFQquoteModal";
import PublishedQuoteModal from "../../../components/Modal/PublishedQuoteModal";
import { useDeleteRFQQuoteMutation } from "../../../apis/createQuote";
import { getPublishQuote } from "../../../redux/slices/publishQuote/action";
import { useNavigate } from "react-router-dom";
import { getRFQListFlag } from "../../../redux/slices/rfqList/action";
import QuotesBanner from "./QuotesBanner";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import {
  baseUrl,
  demandRupifiUrl,
  showDemandRupifiUrl,
} from "../../../commonUtils/commonUtils";
import QuotationRequiredDropdown from "./QuotationRequiredDropdown/QuotationRequiredDropdown";
import DeleteModalNotification from "../../../components/Modal/DeleteModal/DeleteModalNotification/DeleteModalNotification";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copyIcon.svg";

const { Text, Title } = Typography;
const RFQquotes = ({
  quotationList,
  rfq_status,
  id,
  conditionAccepted,
  data,
  auto_generated
}) => {

  // const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const paymentLinkRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentQuote, setCurrentQuote] = useState({});
  const [status, setStatus] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteRFQQuoteApi, { isSuccess: deleteSuccess }] =
    useDeleteRFQQuoteMutation();
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(getRFQListFlag(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSuccess]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelDelete = () => {
    setDeleteModal(false);
  };
  const handleDelete = async () => {
    const response = await deleteRFQQuoteApi({ quote_id: currentQuote.id });
    if (response?.data?.status === true) {
      showSuccessToast(response.data.message);
      setDeleteModal(false);
      setCurrentQuote({});
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const listData = useSelector(rfqListDataSelctor);
  const items = [
    {
      label: "Edit",
      key: "0",
      icon: <EditIcon />,
      onClick: (e) => handleEditQuote(e),
    },
    {
      type: "divider",
    },
    {
      label: "Duplicate",
      key: "1",
      icon: <DuplicateIcon />,
      onClick: (e) => showModal(e),
    },
    {
      label: "Delete",
      key: "2",
      icon: <DeleteIcon />,
      onClick: () => setDeleteModal(true),
    },
  ];
  // const handleDropdown = () => {
  //   setShowDropdown(!showDropdown);
  // };

  const copyLinkToClipboard = async (linkText, text) => {
    // const linkText = paymentLinkRef.current.textContent;

    try {
      await navigator.clipboard.writeText(`${linkText}`);
      showSuccessToast(`${text} copied to clipboard successfully`);
    } catch (err) {
      showErrorToast(`Failed to copy ${text} to clipboard`, err);
    }
  };
  const handleEditQuote = () => {
    navigate(`/admin/rfq/quotes/update-quote/${id}/${currentQuote?.id}`, {
      state: { data: currentQuote?.id, id },
    });
    // navigate("/admin/rfq/quotes/createQuote", {
    //   state: { data: currentQuote.id, id },
    // });
  };
  const handleNavigateOrderView = (item) => {
    const id = item?.order?.id;
    navigate(`/admin/orders/order-details/${id}`);
  };
  const handleNavigateDemand = (url) => {
    // const id = item?.order?.id;
    // to={`${demandRupifiUrl}${item?.checkout_url}`}
    window.open(`${url}`, "_blank");

    // window.open(`${"uate/"}${demandRupifiUrl}${item}`, "_blank");
  };
  const handleAcceptedQuote = (item, Unpublished) => {
    const payload = {
      quoteId: item?.id,
      quoteItems: item?.items,
      quoteTotalPrice: item?.total,
      quoteTime: item?.updated_at,
      quote_status: item?.quote_status,
      index: item?.index,
    };
    dispatch(getPublishQuote(payload));
    setStatus(Unpublished);
    setModalOpen(true);
  };
  const handlePdfDownload = (pdfURL) => {
    const pdfUrl = `${baseUrl}${pdfURL}`;
    window.open(pdfUrl, "_blank");
    // fetch(pdfUrl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "Customer-Quotation.pdf";
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading PDF: ", error);
    //     // showErrorToast("Error downloading PDF!", error);
    //   });
  };
  const copyLinkToClipboardRow = async (params) => {
    try {
      await navigator.clipboard.writeText(params);
      showSuccessToast("Link copied to clipboard successfully");
    } catch (err) {
      showErrorToast("Failed to copy link to clipboard", err);
    }
  };
  return (
    <>
      {quotationList?.map((item, idx) => {
        const fullName = data?.rfq_created_by?.full_name || "N/A";
        const phnNum = data?.rfq_created_by?.phone_number || "N/A";
        const checkoutLink = `${demandRupifiUrl}${item?.checkout_url}`;
        //DONT MAKE ANY CHANGES TO THIS TEXTMSG
        let textMsg = `Hi ${fullName}, please *accept* the link *TO CONFIRM* the order with your 
*Registered No.* ${phnNum} and OTP 
        
*Checkout link*: ${checkoutLink}`;

        return (
          <Col
            style={{
              marginTop: "24px",
            }}
            key={idx}
          >
            <QuotesBanner
              item={item}
              handleAcceptedQuote={handleAcceptedQuote}
              auto_generated={auto_generated}
            />
            <Col
              style={{
                borderRadius:
                  item?.quote_status !== "Published" &&
                  item?.items >= 0 &&
                  (item?.quote_status === "Accepted" ||
                    item?.quote_status === "Order Created")
                    ? "0 0 8px 8px"
                    : "8px",
                boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #eaeaea",
                backgroundColor: "#fff",
              }}
            >
              <Col style={{ padding: "24px" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <Col style={{ display: "flex", gap: "14px" }}>
                    {item?.order?.order_id ? (
                      <>
                        <Text
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#0354a3",
                            cursor: "pointer",
                          }}
                          onClick={() => handleNavigateOrderView(item)}
                        >
                          Order ID: {item?.order?.order_id}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Title level={5} style={{ margin: 0 }}>
                          Quote {item?.index}
                        </Title>
                        {item?.quote_status !== "Accepted" && (
                          <>
                            <Tag
                              style={{
                                minWidth: "99px",
                                height: "32px",
                                // padding: "6px 16px",
                                display: "flex",
                                alignItems: "center",
                                fontWeight: 500,
                                fontSize: " 14px",
                                justifyContent: "center",
                              }}
                              className={`tag-font-style ${
                                colorMapping[item?.quote_status]
                              }`}
                            >
                              {item?.quote_status}
                            </Tag>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  {item?.quote_status !== "Order Created" && (
                    <Col style={{ display: "flex", gap: "16px" }}>
                      {!conditionAccepted &&
                        item?.quote_status === "Published" && (
                          <Button
                            style={{ height: "48px" }}
                            onClick={() => handleAcceptedQuote(item, "remove")}
                          >
                            UNPUBLISH
                          </Button>
                        )}
                      {item?.quote_status === "Accepted" && (
                        <>
                          <Col style={{ display: "flex" }}>
                            <Tooltip
                              title="Copy url"
                              color={"#fff"}
                              key={"#2973f0"}
                            >
                              <Button
                                onClick={() =>
                                  copyLinkToClipboardRow(
                                    item?.quote_pdfs?.customer_quotation_pdfs[0]
                                      ?.short_url
                                  )
                                }
                                style={{
                                  // padding: "7px",
                                  border: "2px solid #f5f5f5",
                                  fontWeight: 500,
                                  width: "40px",
                                  height: "48px",
                                  borderTopRightRadius: "0px",
                                  borderBottomRightRadius: "0px",
                                  borderRight: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                icon={
                                  <CopyIcon
                                    style={{ color: "rgb(41, 115, 240)" }}
                                  />
                                }
                              ></Button>
                            </Tooltip>
                            <Button
                              style={{
                                height: "48px",
                                fontWeight: 500,
                                fontSize: "14px",
                                border: "none",
                                color: "#2973f0",
                                backgroundColor: "#f5f5f5",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              onClick={() =>
                                handlePdfDownload(
                                  item?.quote_pdfs?.customer_quotation_pdfs[0]
                                    ?.file
                                )
                              }
                            >
                              {/* <FileOutlined /> */}
                              VIEW
                            </Button>
                          </Col>
                        </>
                      )}
                      {rfq_status !== "Order Created" && (
                        <Dropdown
                          menu={{ items }}
                          value={item}
                          trigger={["click"]}
                        >
                          <Space>
                            <Button
                              style={{ height: "48px", width: "48px" }}
                              icon={<MoreOutlined />}
                              onClick={() => setCurrentQuote(item)}
                            />
                          </Space>
                        </Dropdown>
                      )}
                    </Col>
                  )}
                </Row>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    flexDirection: "row",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Text style={{ color: "#6c7c8c" }}>
                      {item?.created_at && dateFormat(item?.created_at)}
                    </Text>
                    <Text style={{ color: "#6c7c8c" }}>
                      Items:{" "}
                      <Text
                        style={{
                          color: "#0a243f",
                          paddingLeft: 1,
                          fontWeight: 500,
                        }}
                      >
                        {item?.items || 0}
                      </Text>
                    </Text>
                    <Text style={{ color: "#6c7c8c" }}>
                      Total:{" "}
                      <Text
                        style={{
                          color: "#0a243f",
                          paddingLeft: 1,
                          fontWeight: 500,
                        }}
                      >
                        ₹ {Number(item?.total || 0)?.toFixed(2)}
                      </Text>
                    </Text>
                  </Col>
                  <Col style={{ display: "flex", gap: "1rem" }}>
                    {item?.quote_status !== "Accepted" &&
                      item?.quote_status !== "Order Created" && (
                        // item?.quote_status !== "Order Converted" &&
                        <>
                          <Col style={{ display: "flex" }}>
                            <Tooltip
                              title="Copy url"
                              color={"#fff"}
                              key={"#2973f0"}
                            >
                              <Button
                                onClick={() =>
                                  copyLinkToClipboardRow(
                                    item?.quote_pdfs?.customer_quotation_pdfs[0]
                                      ?.short_url
                                  )
                                }
                                style={{
                                  // padding: "7px",
                                  border: "2px solid #f5f5f5",
                                  fontWeight: 500,
                                  width: "40px",
                                  height: "48px",
                                  borderTopRightRadius: "0px",
                                  borderBottomRightRadius: "0px",
                                  borderRight: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                icon={
                                  <CopyIcon
                                    style={{ color: "rgb(41, 115, 240)" }}
                                  />
                                }
                                disabled={
                                  !item?.quote_pdfs?.customer_quotation_pdfs[0]
                                    ?.file
                                }
                              ></Button>
                            </Tooltip>
                            <Button
                              style={{
                                height: "48px",
                                fontWeight: 500,
                                fontSize: "14px",
                                border: "none",
                                color: "#2973f0",
                                backgroundColor: "#f5f5f5",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              onClick={() =>
                                handlePdfDownload(
                                  item?.quote_pdfs?.customer_quotation_pdfs[0]
                                    ?.file
                                )
                              }
                              disabled={
                                !item?.quote_pdfs?.customer_quotation_pdfs[0]
                                  ?.file
                              }
                            >
                              {/* <FileOutlined /> */}
                              VIEW
                            </Button>
                          </Col>
                          {!conditionAccepted &&
                            !(
                              rfq_status === "Quote accepted" ||
                              rfq_status === "Order Created"
                            ) && (
                              <Button
                                style={{
                                  height: "48px",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  border: "none",
                                  color: "white",
                                  backgroundColor:
                                    item?.mob_all_bmp ||
                                    (item?.quote_status === "Unpublished" &&
                                      item?.items === 0)
                                      ? "#9da7b2"
                                      : "#0354a3",
                                }}
                                disabled={
                                  item?.mob_all_bmp ||
                                  (item?.quote_status === "Unpublished" &&
                                    item?.items === 0)
                                }
                                onClick={() => handleAcceptedQuote(item)}
                              >
                                {item?.quote_status === "Unpublished"
                                  ? "PUBLISH"
                                  : "ACCEPT QUOTE"}
                              </Button>
                            )}
                        </>
                      )}
                    {(item?.quote_status === "Order Created" ||
                      item?.quote_status === "Order Converted") && (
                      <>
                        <Button
                          style={{
                            height: "48px",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          onClick={() => handleNavigateOrderView(item)}
                        >
                          VIEW DETAILS
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
                <span
                  style={{
                    float: "right",
                    fontSize: "12px",
                    color: "gray",
                    marginTop: 10,
                  }}
                >
                  {item?.quote_pdfs?.customer_quotation_pdfs[0]?.file ? null : (
                    <>PDF is generating. Try refresh after 1min</>
                  )}
                </span>
                {(item?.quote_status === "Accepted" ||
                  item?.quote_status === "Order Created" ||
                  item?.quote_status === "Order Converted" ||
                  item?.quote_status === "Published") && (
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#2973f0",
                      marginTop: "20px",
                    }}
                  >
                    <DropdownComponent
                      title={"Purchase order"}
                      data={item?.quote_pdfs?.purchase_order_pdfs}
                      handlePdfDownload={handlePdfDownload}
                    />
                  </Col>
                )}
                {item?.quote_status !== "Order Created" && (
                  <Row
                    style={{
                      flexDirection: "column",
                      gap: "12px",
                      marginTop: "1rem",
                    }}
                  >
                    {item?.quote_comments && (
                      <>
                        <Col>
                          <Title level={5} style={{ margin: 0 }}>
                            Comments
                          </Title>
                        </Col>
                        <Col>
                          <Text style={{ color: "#6c7c8c" }}>
                            {item?.quote_comments}
                          </Text>
                        </Col>
                      </>
                    )}

                    {(item?.quote_status === "Published" ||
                      item?.quote_status === "Unpublished" ||
                      item?.quote_status === "Accepted" ||
                      item?.quote_status === "Order Created" ||
                      item?.quote_status === "Order Converted") && (
                      <>
                        {/* <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2973f0",
                        }}
                        // onClick={() =>
                        //   handlePdfDownload(
                        //     item?.quote_pdfs?.quotation_required_pdf
                        //   )
                        // }
                      >
                        <Text style={{ color: "#2973f0", cursor: "pointer" }}>
                          Quotation required (pdf/xls)
                        </Text>
                        <DownOutlined
                          style={{ paddingLeft: "6px", cursor: "pointer" }}
                        />
                      </Col> */}
                        <QuotationRequiredDropdown
                          title={"Quotation required (pdf/xls)"}
                          data={item?.quote_pdfs?.quotation_required_pdfs}
                          handlePdfDownload={handlePdfDownload}
                        />
                      </>
                    )}
                  </Row>
                )}
              </Col>
              {item?.quote_status === "Accepted" && (
                <>
                  <Col
                    style={{
                      borderTop: "1px solid #dedede",
                      padding: "24px",
                      gap: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <Col
                      className="payment-link"
                      style={{
                        border: "solid 1px rgba(0, 0, 0, 0)",
                        backgroundColor: "#e8f6c6",
                        padding: "12px",
                      }}
                    >
                      <Col className="link-wrapper">
                        <Text>Customer checkout link:</Text>
                        <Col
                          // ref={paymentLinkRef}
                          // to={`${demandRupifiUrl}${item?.checkout_url}`}
                          onClick={() =>
                            handleNavigateDemand(
                              `${demandRupifiUrl}${item?.checkout_url}`
                            )
                          }
                          target="_blank"
                          // rel="noopener noreferrer"
                          className="payment-links"
                          style={{ cursor: "pointer" }}
                        >
                          {showDemandRupifiUrl || "N/A"}
                        </Col>
                      </Col>
                      <Tooltip title="Copy url" color={"#fff"} key={"#2973f0"}>
                        <Col
                          className="link-wrapperI"
                          onClick={() =>
                            copyLinkToClipboard(
                              `${demandRupifiUrl}${item?.checkout_url}`,
                              "Link"
                            )
                          }
                        >
                          <FileIcon />
                          <Text>Copy link</Text>
                        </Col>
                      </Tooltip>
                    </Col> */}
                    <Col
                      className="payment-link payment-link-container"
                      style={{
                        border: "solid 1px rgba(0, 0, 0, 0)",
                        backgroundColor: "#e8f6c6",
                        padding: "12px",
                      }}
                    >
                      <Col>
                        <Text>
                          Hi {data?.rfq_created_by?.full_name || "N/A"}, please
                          accept the link to confirm the order with your
                          <br />
                          <b> Registered No. </b>
                          {data?.rfq_created_by?.phone_number} and OTP
                        </Text>
                      </Col>
                      <Col className="link-wrapper link-wrapper-container">
                        <Col className="link-wrapper-sub-container">
                          <Text>Customer Checkout link:</Text>
                          <Col
                            // ref={paymentLinkRef}
                            // to={`${demandRupifiUrl}${item?.checkout_url}`}
                            onClick={() =>
                              handleNavigateDemand(
                                `${demandRupifiUrl}${item?.checkout_url}`
                              )
                            }
                            target="_blank"
                            // rel="noopener noreferrer"
                            className="payment-links"
                            style={{ cursor: "pointer" }}
                          >
                            {showDemandRupifiUrl || "N/A"}
                            {/* {string_dot(item?.checkout_url, 30) || "N/A"} */}
                          </Col>
                        </Col>
                        <Tooltip
                          title="Copy Text"
                          color={"#fff"}
                          key={"#2973f0"}
                        >
                          <Col
                            className="link-wrapperI"
                            onClick={() => copyLinkToClipboard(textMsg, "Text")}
                          >
                            <FileIcon />
                            <Text>Copy Text</Text>
                          </Col>
                        </Tooltip>
                      </Col>
                    </Col>
                    <Text
                      style={{
                        marginTop: "10px",
                        display: "block",
                        opacity: 0.6,
                        color: "#0a243f",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      This quote will be auto converted to order if the customer
                      completes the payment using this link.
                    </Text>
                  </Col>
                </>
              )}
            </Col>
          </Col>
        );
      })}
      <RFQquoteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        showModal={showModal}
        handleCancel={handleCancel}
        handleOk={handleOk}
        currentQuote={currentQuote}
      />
      <PublishedQuoteModal
        token={status}
        isModalOpen={modalOpen}
        setIsModalOpen={setModalOpen}
        phone_number={listData?.rfq_created_by?.phone_number}
        email={listData?.rfq_created_by?.email}
      />
      <DeleteModalNotification
        open={deleteModal}
        handleOk={handleCancelDelete}
        handleSave={handleDelete}
        data={`Quote ${currentQuote?.index}`}
        title="Delete Quote"
        ButtonText="Delete Quote"
      />
    </>
  );
};

export default RFQquotes;
