import { Col, Radio, Row, Select, Typography, Button, Upload,Tag ,notification} from "antd";
import CustomInput from "./ProjectCustomInput";
import { PlusOutlined } from "@ant-design/icons";
// import mobCreditIcon from "../../../public/mobCreditTag.png";
// eslint-disable-next-line
import { useEffect, useState } from "react";
import {
  stateOptions,
  cityOptions,
  checkEmptyValidation,
  checkPhoneNumberValidation,
  checkgstOptionalValidation,
  MOB_CREDIT_TAG_CHECK
} from "../../../commonUtils/commonUtils";
import { useLazyGetCustomersAddressQuery } from "../../../apis/customer";

const ProjectsCreateModal = ({
  setUserNewAddress,
  userNewAddress,
  addressType,
  setAddressType,
  isSubmitClick,
  // userStoredAddress,
  setFile,
  setFileSize,
  addAddressFlag,
  SetaddAddressFlag,
  cityDisabled,
  setcityDisabled,
  stateDisabled,
  setstateDisabled,
  seller_id,
  file
}) => {
// eslint-disable-next-line
  const [api, contextHolder] = notification.useNotification();

  // let disabledCheck = isDisabledBillingAddress(userAllAddress, userNewAddress);
  // const currentAddressIdCheck = userNewAddress?.id;
  const { Text } = Typography;
  const { Option } = Select;

// eslint-disable-next-line
  const [address_tag, setAddress_tag] = useState("");

  const [selectedAddressId, setSelectedAddressId] = useState(0);

  const [getAllSellerAddressAPI, { data: userStoredAddress }] =
  useLazyGetCustomersAddressQuery();
  useEffect(() => {
    if (seller_id) {
      getAllSellerAddressAPI({ phone_number: seller_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    SetaddAddressFlag(!addAddressFlag);
  };

  const handleInputChange = (event) => {
    if (
      event?.target?.name === "gst_number" &&
      event?.target?.value?.length > 15
    )
      return;
      if (event.target.name === "site_delivery") {
     
        const selectedAddress = userStoredAddress?.data?.find(value => value.id ===parseInt(event.target.value));
        setSelectedAddressId(event.target.value);
       setUserNewAddress({
      ...userNewAddress,
      [event.target.name]: event.target.value,      
      // eslint-disable-next-line
      ["Projectcity"]: selectedAddress?.city,
      // eslint-disable-next-line
      ["Projectstate"]: selectedAddress?.state,
      
    });
   
  }
  else{
    setUserNewAddress({
      ...userNewAddress,
      [event.target.name]: event.target.value,
    });
  }
  };
  const handleAddressTag = (event) => {
    // if (
    //   userNewAddress?.address_tag === "Billing" &&
    //   event?.target?.value !== "Billing"
    // ) {
    //   setOpenBillingModal(true);
    // } else {
    setUserNewAddress({
      ...userNewAddress,
      [event.target.name]: event.target.value,
    });
    // }
    // setAddress_tag(event.target.value);
  };

  // const handleBillingAddressCheckBox = (event) => {
  //   const checked = event.target.checked;
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   if (!checked) {
  //     setOpenBillingModal(true);
  //   } else {
  //     setUserNewAddress({
  //       ...userNewAddress,
  //       [name]: value,
  //     });
  //   }
  // };
  const bytesToKB = (bytes) => {
    return bytes / 1024;
  };
// eslint-disable-next-line
  const openNotificationWithIcon = (type, description) => {
    api[type]({
      message: `${"warning"} !`,
      description: description,
    });
  };
  const addFile = ({ file, fileList }) => {

    const fileSizeKB = bytesToKB(fileList[0].size);
    if (fileSizeKB > 500) {
      // openNotificationWithIcon(ERROR, FILE_SIZE_SHOULD_BE_LESS_THAN_200KB);
      return false;
    }
   
    setFileSize(fileSizeKB.toFixed(2));
    setFile(...fileList);
  };
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  const handleStateChange = (key, value) => {
    setUserNewAddress({
      ...userNewAddress,
      [key]: value,
      // eslint-disable-next-line
      ["state"]: value,
    });
  
    if(value)
      {
      setstateDisabled(true);
      }
      else
      {
        setstateDisabled(false);
      }
  };
  const handleCityChange = (key, value) => {
   
    const selectedState = cityOptions?.find(city => city.value === value)?.state;

    setUserNewAddress({
      ...userNewAddress,
      [key]: value,
      // eslint-disable-next-line
      ["city"]: value,
      // eslint-disable-next-line
      ["state"]: selectedState,
      // eslint-disable-next-line
      ["Projectstate"]: selectedState,
      
    });
 
   

    if(value)
    {
    setcityDisabled(true);
    setstateDisabled(true);
    }
    else
    {
      setcityDisabled(false);
      setstateDisabled(false);
    }
  };
  return (
    <>
      <Row className="sectionDivider"></Row>
      <Col className="inputSectionFirst bg-gradient-section">
        <Upload
          listType="picture-card"
          beforeUpload={() => false}
          btnText="Add Another"
          showUploadList={true}
          maxCount={1}
          onChange={addFile}
          fileList={file ? [file] : []}
        >
          {uploadButton}
        </Upload>
        <CustomInput
          label={
            <Row className="inputLabelParent">
              <Text className="inputLabelContent">Project name</Text>
              <Text className="redStar">*</Text>
            </Row>
          }
          inputClass={`signup-modal-inp ${
            isSubmitClick &&
            !checkEmptyValidation(userNewAddress?.project_name) &&
            "request-price-inp-err"
          }`}
          labelClass={"address-mdoal-input-label"}
          value={userNewAddress?.project_name}
          name={"project_name"}
          handleInputChange={(event) => handleInputChange(event)}
        />
<Col className="addressInputContentParent">
        <Col className="">
          {/* <CustomInput
            label={
              <Row className="inputLabelParent">
                <Text className="inputLabelContent">City</Text>
                <Text className="redStar">*</Text>
              </Row>
            }
            inputClass={`signup-modal-inp ${
              isSubmitClick &&
              !checkEmptyValidation(userNewAddress?.Projectcity) &&
              "request-price-inp-err"
            }`}
            labelClass={"address-mdoal-input-label"}
            value={userNewAddress?.Projectcity}
            name={"Projectcity"}
            handleInputChange={(event) => handleInputChange(event)}
          /> */}
          <Col className={"address-mdoal-input-label"}>
        
        <Row className="inputLabelParent">
          <Text className="inputLabelContent">City</Text>
          <Text className="redStar">*</Text>
        </Row>
        <Col>
          <Select
              style={{ width: "100%", height: "40px" }}
              className={
                isSubmitClick && !checkEmptyValidation(userNewAddress?.Projectcity)
                  ? "modifiedStateContainer"
                  : "address-mdoal-state"
              }
              showSearch
              placeholder="--Select or Search  city--"
              onChange={(e) => handleCityChange("Projectcity", e)}
              value={userNewAddress?.Projectcity}
            >
              <Option disabled value="">
                --Select or Search city--
              </Option>
              {cityOptions?.sort((a, b) => a.value.localeCompare(b.value))?.map((option, index) => (
                <Option key={index} value={option?.value}>
                  {option?.label}
                </Option>
              ))}
            </Select>
            </Col>
            </Col>
        </Col>
        <Col className={"address-mdoal-input-label"}>
        
          <Row className="inputLabelParent">
            <Text className="inputLabelContent">State</Text>
            <Text className="redStar">*</Text>
          </Row>
          <Col>
            <Select
              style={{ width: "100%", height: "40px" }}
              className={
                isSubmitClick && !checkEmptyValidation(userNewAddress?.Projectstate)
                  ? "modifiedStateContainer"
                  : "address-mdoal-state"
              }
              showSearch
              placeholder="--Select or Search  state--"
              onChange={(e) => handleStateChange("Projectstate", e)}
              value={userNewAddress?.Projectstate}
            >
              <Option disabled value="">
                --Select or Search state--
              </Option>
              {stateOptions?.map((option, index) => (
                <Option key={index} value={option?.value}>
                  {option?.label}
                </Option>
              ))}
            </Select>
          </Col>
         
        </Col>
        </Col>
      </Col>
      <Col className="inputSectionFirst">
        <p>
          <Text className="text-project-add-caption">Add new project address (For site delivery)</Text>
        </p>
        <Button onClick={handleClick} className="btn-address-select width-100">
        {addAddressFlag ? (
    "Select from saved addresses"
  ) : (
    <>
      <PlusOutlined style={{ marginRight: 8 }} />
      Add new address
    </>
  )}
        </Button>
      </Col>
      {!addAddressFlag?(
      <Col className="inputSectionFirst">
        {userStoredAddress?.data?.filter(value => !value?.project).map((value, index) => {
          const mob_credit_check = MOB_CREDIT_TAG_CHECK.includes(
            value?.address_tag
          );
       
          return (
            <div
              key={index}             
              className={`user-add-address ${String(selectedAddressId) === String(value.id) ? 'active' : ''}`}
              onClick={() =>
                document.getElementById(`address-${index}`).click()
              }
            >
              <Col className="d-flex gap-8">
                <input
                  id={`address-${index}`}
                  type="radio"
                  name="site_delivery"
                  value={value?.id}
                  onChange={(event) => handleInputChange(event)}
                />
                <Col className="width-100">
                  <Col className="d-flex  justify-content-space">
                    <Text className="text-address-heading"> {value?.name}</Text>
                    <Text >
                    {(value?.address_tag ||
                                value?.address_type) && (
                                <>
                                  {mob_credit_check ? (
                                    <>
                                      {/* <CustomImage
                                        src={mobCreditIcon}
                                        width={95}
                                        height={28}
                                        alt="Mob credit icon"
                                      /> */}
                                      {/* {address?.address_type === "Billing" && (
                                        <Tag className="userModalButton">
                                          Billing
                                        </Tag>
                                      )} */}
                                    </>
                                  ) : (
                                    <>
                                      {/* {address?.address_type === "Billing" && (
                                        <Tag className="userModalButton">
                                          Billing
                                        </Tag>
                                      )} */}
                                      {value?.address_tag && (
                                        <Tag className="userModalButton">
                                          {value?.address_tag}
                                        </Tag>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                    </Text>
                  </Col>
                 <Text className="card-project-text"> Project: {value?.project ? value.project.project_name : <span className="text-danger-na">NA</span>}</Text>
                  <address style={{fontStyle:"unset"}}>
                    {value?.address_line_1} {value?.address_line_2}
                    <br />
                    {value?.city} {value?.state}
                    {" - "}
                    {value?.pincode}
                  </address>
                </Col>
              </Col>
            </div>
          );
        })}
      </Col>
      ):(
        <>
      <Col className="inputSectionFirst">
        <CustomInput
          label={
            <Row className="inputLabelParent">
              <Text className="inputLabelContent">Name (Project manager)</Text>
              <Text className="redStar">*</Text>
            </Row>
          }
          inputClass={`signup-modal-inp ${
            isSubmitClick &&
            !checkEmptyValidation(userNewAddress?.name) &&
            "request-price-inp-err"
          }`}
          labelClass={"address-mdoal-input-label"}
          value={userNewAddress?.name}
          name={"name"}
          handleInputChange={(event) => handleInputChange(event)}
        />

        <Col className="addressInputContentParent">
          <CustomInput
            label={
              <Row className="inputLabelParent">
                <Text className="inputLabelContent">
                  Business mobile (for OTP)
                </Text>
                <Text className="redStar">*</Text>
              </Row>
            }
            inputClass={`signup-modal-inp ${
              isSubmitClick &&
              !checkPhoneNumberValidation(userNewAddress?.phone_number) &&
              "request-price-inp-err"
            }`}
            labelClass={"address-mdoal-input-label"}
            value={userNewAddress?.phone_number}
            name={"phone_number"}
            maxLength={10}
            handleInputChange={(event) => handleInputChange(event)}
          />

          <CustomInput
            label={"GST (Optional)"}
            ContainerClass={"responsiveGstParent"}
            inputClass={`signup-modal-inp ${
              isSubmitClick &&
              !checkgstOptionalValidation(userNewAddress?.gst_number) &&
              "request-price-inp-err"
            }`}
            // inputClass={`signup-modal-inp`}
            labelClass={"address-mdoal-input-label responsiveGstLabelParent"}
            value={userNewAddress?.gst_number}
            name={"gst_number"}
            handleInputChange={(event) => handleInputChange(event)}
          />
        </Col>
        <Col className="addressInputContentParent">
          <CustomInput
            label={
              <Row className="inputLabelParent">
                <Text className="inputLabelContent">
                  Site person(for delivery)
                </Text>
              </Row>
            }
            // inputClass={`signup-modal-inp ${
            //   isSubmitClick &&
            //   !checkEmptyValidation(userNewAddress?.name) &&
            //   "request-price-inp-err"
            // }`}
            inputClass={`signup-modal-inp`}
            labelClass={"address-mdoal-input-label"}
            value={userNewAddress?.site_person}
            name={"site_person"}
            handleInputChange={(event) => handleInputChange(event)}
          />
          <CustomInput
            label={
              <Row className="inputLabelParent">
                <Text className="inputLabelContent">Site person mobile</Text>
              </Row>
            }
            // inputClass={`signup-modal-inp ${
            //   isSubmitClick &&
            //   !checkPhoneNumberValidation(userNewAddress?.phone_number) &&
            //   "request-price-inp-err"
            // }`}
            inputClass={`signup-modal-inp`}
            labelClass={"address-mdoal-input-label"}
            value={userNewAddress?.site_person_mobile}
            name={"site_person_mobile"}
            handleInputChange={(event) => handleInputChange(event)}
          />
        </Col>
 

      {/* <Row className="sectionDivider"></Row> */}

     
      <CustomInput
          label={"Google Maps Link"}
          inputClass={`signup-modal-inp`}
          labelClass={"address-mdoal-input-label"}
          value={userNewAddress?.google_map_link}
          name={"google_map_link"}
          handleInputChange={(event) => handleInputChange(event)}
        />
        

        
        <CustomInput
          label={"House no/ Building name"}
          inputClass={`signup-modal-inp`}
          labelClass={"address-mdoal-input-label"}
          value={userNewAddress?.address_line_1}
          name={"address_line_1"}
          handleInputChange={(event) => handleInputChange(event)}
        />

        <CustomInput
          label={"Road/ Area/ Colony"}
          inputClass={`signup-modal-inp`}
          labelClass={"address-mdoal-input-label"}
          value={userNewAddress?.address_line_2}
          name={"address_line_2"}
          handleInputChange={(event) => handleInputChange(event)}
        />

        <Col className="addressInputContentParent">
          <CustomInput
            label={"Pincode"}
            inputClass={`signup-modal-inp`}
            labelClass={"address-mdoal-input-label"}
            value={userNewAddress?.pincode}
            name={"pincode"}
            handleInputChange={(event) => handleInputChange(event)}
          />

          <CustomInput
            label={
              <Row className="inputLabelParent">
                <Text className="inputLabelContent">City</Text>
                <Text className="redStar">*</Text>
              </Row>
            }
            disabled={cityDisabled}
            inputClass={`signup-modal-inp ${
              isSubmitClick &&
              !checkEmptyValidation(userNewAddress?.city) &&
              "request-price-inp-err"
            }`}
            labelClass={"address-mdoal-input-label"}
            value={userNewAddress?.city}
            name={"city"}
            handleInputChange={(event) => handleInputChange(event)}
          />
        </Col>
        <Col className="addressInputContentParent">
          <Col className={"address-mdoal-input-label"}>
          
            <Row className="inputLabelParent">
              <Text className="inputLabelContent">State</Text>
              <Text className="redStar">*</Text>
            </Row>
            <Col>
              <Select
                style={{ width: "100%", height: "40px" }}
                className={
                  isSubmitClick && !checkEmptyValidation(userNewAddress?.state)
                    ? "modifiedStateContainer"
                    : "address-mdoal-state"
                }
                showSearch
                placeholder="--Select or Search  state--"
                onChange={(e) => handleStateChange("state", e)}
                value={userNewAddress?.state}
                disabled={stateDisabled}
              >
                <Option disabled value="">
                  --Select or Search state--
                </Option>
                {stateOptions?.map((option, index) => (
                  <Option key={index} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </Col>
          
          </Col>
        <Col>
        <CustomInput
          label={
            <Row className="inputLabelParent">
              <Text className="inputLabelContent">Email</Text>
            </Row>
          }
          inputClass={`signup-modal-inp`}
          labelClass={"address-mdoal-input-label"}
          value={userNewAddress?.email}
          name={"email"}
          handleInputChange={(event) => handleInputChange(event)}
        /></Col>
        </Col>
        
        <Col className="editAddressButtonParent">
          <Text className="editAddressButtonLabel">Save address as</Text>

          <Row className="editAddressButtonContentParent">
            <Radio.Group
              value={addressType}
              className="radioGroup"
              name={"address_tag"}
              onChange={(e) => {
                setAddressType(e?.target?.value);
                handleAddressTag(e);
              }}
            >
              <Radio.Button
                name={"address_tag"}
                className={`editAddressButton ${
                  userNewAddress?.address_tag === "Home" &&
                  "active-edit-address-tag"
                }`}
                value="Home"
              >
                Home
              </Radio.Button>
              <Radio.Button
                name={"address_tag"}
                className={`editAddressButton ${
                  userNewAddress?.address_tag === "Office" &&
                  "active-edit-address-tag"
                }`}
                value="Office"
              >
                Office
              </Radio.Button>
            </Radio.Group>
          </Row>
          {/* <Col className="editAddressCheckboxWrapper">
            <Text className="editAddressButtonLabel">Save address as</Text>

            <Row className="editAddressCheckboxWrapperChild">
              <Checkbox
                name={"address_type"}
                // className={`editAddressButton ${
                //   userNewAddress?.address_tag === "Billing Address" &&
                //   "active-edit-address-tag"
                // }`
                onChange={handleBillingAddressCheckBox}
                value="Billing"
                disabled={
                  disabledCheck ||
                  !checkgstValidation(userNewAddress?.gst_number)
                }
                checked={userNewAddress?.address_type === "Billing"}>
                Mark as billing address{" "}
              </Checkbox>
              {
                // !currentAddressIdCheck &&
                !checkgstValidation(userNewAddress?.gst_number) && (
                  <Text className="address-billing-tag-error">
                    Please add GSTIN to include the ‘Billing Address’ tag
                  </Text>
                )
              }
            </Row>
          </Col> */}
        </Col>
      </Col>

      <Row className="sectionDivider"></Row>
      {/* <UserBillingConfirmModal
        open={openBillingModal}
        handleCancel={handleCancelBillingModal}
        handleSave={handleSaveBillingModal}
      /> */}
      </>
    )}
    </>
  );
};

export default ProjectsCreateModal;
