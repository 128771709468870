import React, { useState } from "react";
import { Col, Typography } from "antd";
import { ReactComponent as AlertInfoIcon } from "../../../assets/icons/icon.svg";
const { Text } = Typography;
const QuotesBanner = ({ item, handleAcceptedQuote ,auto_generated}) => {
  
  const [
    bannerShow,
    //  setBannerShow
  ] = useState(true);

  const handleBannerRemove = () => {
    // setBannerShow(false);
    handleAcceptedQuote(item);
  };
  return (
    <>
      {bannerShow && (
        <Col>
          {item?.quote_status !== "Published" && (
            <>
              {item?.items >= 0 &&
              (item?.quote_status === "Accepted" ||
                item?.quote_status === "Order Created" ||
                item?.quote_status === "Order Converted") ? (
                <>
                  <Col
                    style={{
                      backgroundColor: "#4fb589",
                      padding: "12px 11px",
                      borderRadius: "10px 10px 0 0",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ color: "#ffffff", fontWeight: 500 }}>
                      {item?.quote_status === "Accepted"
                        ? `Quotation accepted by customer`
                        : `Quote ${item?.index} converted to order`}
                    </Text>

                    {item?.quote_status === "Accepted" && (
                      <Text
                        style={{
                          color: "#ffffff",
                          fontWeight: 500,
                          borderBottom: "1px solid",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={handleBannerRemove}
                      >
                        Remove
                      </Text>
                    )}
                  </Col>
                </>
              ) : (
                <>     
                {auto_generated ?(
                  <>
                  <Col
                        style={{
                          backgroundColor: "#ffebe6",
                          padding: "12px 11px",
                          borderRadius: "10px 10px 0 0",
                          display:"flex",
                          alignItems:"center",
                          gap:"16px"
                        }}
                      >
                        <AlertInfoIcon/>
                        <Text style={{ color: "#f0483e", fontWeight: 500 }}>
                          
                        This is an auto generated quote. Please update price and BMP to publish
                        </Text>
                      </Col>
                  </>
                ):(     
                  <>    
                  {item?.mob_all_bmp &&
                    item?.items > 0 && (
                      <Col
                        style={{
                          backgroundColor: "#9da7b2",
                          padding: "12px 11px",
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        <Text style={{ color: "#ffffff", fontWeight: 500 }}>
                        Change the BMP to publish quote
                        </Text>
                      </Col>
                  )}
                    </>
                  )}  
                </>
              )}
            </>
          )}
        </Col>
      )}
    </>
  );
};

export default QuotesBanner;
