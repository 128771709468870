import React from "react";
import { Button,  Col, Modal, Typography ,message,Input  } from "antd";
import "./UpdateRedundantModal.css";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";

const { Text } = Typography;
const { TextArea } = Input;

const UpdateRedundantModal = (props) => {
  const { isModalOpen, setIsModalOpen, handleSaveApi,handleRedundantChange,redundantData,handleTextChange } = props;

  const handleSave = async () => {
    try {
      await handleSaveApi();
      setIsModalOpen(false); // Close the modal after successful save
    } catch (error) {
     message.error("Failed to update RFQ status.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const redundantOptions = [
     {
       key: 1,
       value: "Price",
       label: "Price",
     },
     {
      key: 2,
      value: "Delivery Cost",
      label: "Delivery Cost",
    },
    {
      key: 3,
      value: "Delivery time",
      label: "Delivery time",
    }, {
      key: 4,
      value: "Payment term/ Credit request",
      label: "Payment term/ Credit request",
    },{
      key : 5,
      value: "Without tax request",
      label: "Without tax request"
    },{
      key :6,
      value : "Out of service limits",
      label : "Out of service limits",

    },
    {
      key:7,
      value:"Materials we don't supply",
      label : "Materials we don't supply "
    },
    {
      key:8,
      value:"Bad customer service experience",
      label : "Bad customer service experience"
    },
    {
      key : 9,
      value: "Other",
      label : "Other"
    }
   ];

  return (
    <>
      <Modal
        onCancel={handleCancel}
        title={"Are you sure?"}
        // closeIcon={null}
        // open={true}
        onOk={handleCancel}
        open={isModalOpen}
        style={{ color: "#0a243f", padding: "0px" }}
        footer={[
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              borderTop: "1px solid #dedede",
              borderRadius: "0px 0px 8px 8px",
              padding: "20px 0px",
              boxShadow: "0 -6px 10px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <Button
              onClick={handleCancel}
              style={{
                height: "48px",
                fontWeight: 500,
                fontSize: "14px",
                maxWidth: "120px",
                width: "100%",
              }}
            >
              No
            </Button>
           
            <Button
            //   loading={loading}
            //   disabled={loading}
              onClick={handleSave}
              disabled={!redundantData?.reason_for_redundant?.trim()}
              style={{
                height: "48px",
                maxWidth: "200px",
                width: "100%",
                fontWeight: 500,
                fontSize: "14px",
                color: "white",
                backgroundColor: !redundantData?.reason_for_redundant?.trim() ? "#e0e0e0" : "#f0483e",
                border: "none",
              }}
            >
              YES, CHANGE STATUS
            </Button>
          
          </Col>,
        ]}
      >
        <Col
          style={{
            display: "flex",
            height: "300px",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
        
            <Text
              style={{
                color: "#0a243f",
                marginBottom: "15px",
                fontWeight: 450,
                fontSize: "14px",
              }}
            >
             Are you sure you want to change the status to redundant/ lost?
              The customer status will not change and they will not be notified.
            </Text>
          
           
            <label>Reason for lost/ redundant <span style={{color:'red'}}>*</span> </label>
            <CustomSelect
            
            placeholder={"Reason for lost/ redundant *"}
            width={"100%"}
            options={redundantOptions}
            handleChange={handleRedundantChange}
            value={redundantData?.reason_for_redundant ? redundantData.reason_for_redundant : "Select"}
            style={{marginBottom:"10px"}}
           />



          <label style={{marginTop:"10px"}}>Additional comments (optional)</label>
          <TextArea
            style={{marginTop:"10px"}}
            value={redundantData?.additional_comment ? redundantData.additional_comment : ""}
            onChange={handleTextChange}
            autoSize={{ minRows: 3, maxRows: 5 }}
                />

          </Col>
          
        </Col>
       
      </Modal>
    </>
  );
};

export default UpdateRedundantModal;
