import React, { useState, useEffect } from "react";
import "./GstCell.css";
import {
  priceAfterTax,
  totalPrice,
} from "../../../../../../../commonFuntions/CommonUtilFunctions";
import { Col, Dropdown, Input, Menu, Typography } from "antd";
import { ReactComponent as CheckIcon } from "../../../../../../../assets/icons/checkIcon.svg";
import { ReactComponent as SelectDownIcon } from "../../../../../../../assets/icons/selectDownIcon.svg";
import { ReactComponent as PurpleDownIcon } from "../../../../../../../assets/icons/purpleDownIcon.svg";

import {
  AfterTaxText,
  BMPPrice,
  GSTContainer,
  GstInput,
  MOBButton,
  MOBHeading,
  MOBSubHeading,
  MenuItem,
  MobDropDownAddedText,
  MobDropDownContainer,
  RowDropDownContainer,
} from "../../Styled";
import Toggle from "../../../../../../../components/Discount/Toggle";

const { Text } = Typography;
const GstCell = ({
  dataSource,
  tableIndex,
  params,
  record,
  setDataSource,
  editCondition,
}) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [gst, setGst] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountError, setDiscountError] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [mobMargin, setMobMargin] = useState("");
  const [active, setActive] = useState("");

  const handleGst = (e) => {
    const value = e.target.value;
    // const value = e.target.value.split(" ");
    const updatedValue = value;
    // const updatedValue = value[0] || "";
    if (updatedValue === "") {
      setGst("");
    } else if (updatedValue === "0") {
      setGst(0);
    } else {
      setGst(parseInt(updatedValue) || "");
    }
  };

  const handleDiscount = (e) => {
    const value = e.target.value.split(" ");
    const updatedValue = value[1] || "";
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(updatedValue)) {
      if (parseFloat(updatedValue || 0) > parseFloat(record?.after_tax || 0)) {
        setDiscountError(true);
      } else {
        setDiscount(updatedValue || "");
        setDiscountError(false);
      }
    }
    if (updatedValue === "") {
      setDiscount("");
      setDiscountError(false);
    }
  };
  const handleQuantity = (e) => {
    const value = e.target.value;
    const decimalPattern = /^(?:\d+|\d+\.\d{0,2})$/;
    if (decimalPattern.test(value)) {
      setQuantity(value || "");
    }
    if (value === "") {
      setQuantity("");
    }
  };

  const onSelect = (e) => {
    setDataSource((prevData) => {
      return prevData?.map((table, index) => {
        return tableIndex === index
          ? table.map((item) => {
              if (item?.key === record?.key) {
                return {
                  ...item,
                  amount_in: e === 0 ? "amount" : "percentage",
                  amount: 0,
                };
              }
              return item;
            })
          : table;
      });
    });
    setActive(e);
    setMobMargin("");
    setErrorMessage(false);
  };

  const handleMobMargin = (e) => {
    const value = e.target.value;
    const decimalPattern = /^-?$|^-?\d+(\.\d{0,2})?$/;
    if (decimalPattern.test(value)) {
      if (active === 0) {
        if (parseFloat(value) > parseFloat(record?.after_tax || 0)) {
          setErrorMessage(true);
        } else {
          setMobMargin(value);
          setErrorMessage(false);
        }
      } else {
        if (parseFloat(value) > 100) {
          setErrorMessage(true);
        } else {
          setMobMargin(value);
          setErrorMessage(false);
        }
      }
    }
    if (value === "") {
      setMobMargin("");
    }
  };
  const handleSaveMobMargin = () => {
    const negative =
      parseFloat(record?.after_tax) * parseFloat(record?.quantity || 0) -
      parseFloat(mobMargin) * parseFloat(record?.quantity || 0);
    // const negative = parseFloat(record.total) - parseFloat(mobMargin);
    const bmp =
      active === 0
        ? negative < 0
          ? 0
          : negative
        : (parseFloat(record?.after_tax) * parseFloat(record?.quantity || 0)) /
          (1 + parseFloat(mobMargin) / 100);
    // : parseFloat(record.total) / (1 + parseFloat(mobMargin) / 100);
    // : parseFloat(record.total) -
    //   (parseFloat(record.total) * mobMargin) / 100;
    setDataSource((prevData) => {
      return prevData?.map((table, index) => {
        return tableIndex === index
          ? table?.map((item) => {
              if (item?.key === record?.key) {
                return {
                  ...item,
                  amount: mobMargin,
                  bmp_price: mobMargin ? bmp : "",
                };
              }
              return item;
            })
          : table;
      });
    });
    setErrorMessage(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  useEffect(() => {
    setGst(record?.gst);
    setDiscount(record?.discount);
    setQuantity(record?.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);
  useEffect(() => {
    if (record?.amount === 0) {
      setMobMargin(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);
  useEffect(() => {
    if (editCondition) {
      // const bmp =
      //   record?.amount_in === "amount"
      //     ? parseFloat(record?.total) - parseFloat(record?.amount || 0)
      //     : parseFloat(record?.total || 0) /
      //       (1 + parseFloat(record?.amount || 0) / 100);
      // parseFloat(record.after_tax) -
      //   (parseFloat(record.after_tax) * record.amount) / 100;
      setMobMargin(record?.amount);
      setActive(record?.amount_in === "amount" ? 0 : 1);
      // setDataSource((prevData) => {
      //   return prevData?.map((table, index) => {
      //     return tableIndex === index
      //       ? table?.map((item) => {
      //           if (item?.key === record?.key) {
      //             return {
      //               ...item,
      //               // bmp_price: bmp,
      //             };
      //           }
      //           return item;
      //         })
      //       : table;
      //   });
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCondition]);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setOpen(false);
      handleSaveMobMargin();
    }
  };
  const handleSave = () => {
    const afterTax = priceAfterTax({
      gst: gst,
      before_tax: record?.before_tax,
    });
    const total = totalPrice({
      gst: gst,
      before_tax: record?.before_tax,
      discount: discount,
      quantity: quantity,
    });
    // const bmp = parseFloat(afterTax || 0) * parseFloat(quantity || 0);
    if (parseFloat(discount || 0) > parseFloat(record?.after_tax || 0)) {
      setDiscountError(true);
    } else {
      setDiscountError(false);
    }
    const bmp =
      record?.amount_in === "amount"
        ? parseFloat(afterTax || 0) * parseFloat(quantity || 0) -
          parseFloat(record?.amount || 0) * parseFloat(quantity || 0)
        : (parseFloat(afterTax || 0) * parseFloat(quantity || 0)) /
          (1 + parseFloat(record?.amount || 0) / 100);
    // const bmp =
    //   record.amount_in === "amount"
    //     ? parseFloat(total) - parseFloat(record.amount || 0)
    //     : parseFloat(total) / (1 + parseFloat(record.amount || 0) / 100);

    setDataSource((prevData) => {
      return prevData?.map((table, index) => {
        return tableIndex === index
          ? table?.map((item) => {
              if (item?.key === record?.key) {
                return {
                  ...item,
                  gst: gst,
                  after_tax: afterTax,
                  discount: discount,
                  quantity: quantity,
                  total: total,
                  bmp_price: bmp,
                  // amount: 0,
                };
              }
              return item;
            })
          : table;
      });
    });
  };

  const renderDropdownOptions = () => (
    <Menu className="add_margin_dropdown" style={{ borderRadius: "16px" }}>
      <MenuItem>
        <MOBHeading>MOB margin</MOBHeading>
        <MOBSubHeading>₹ margin to be added on with tax amount</MOBSubHeading>
        <Col style={{ position: "relative" }}>
          <Input
            className={`${
              errorMessage ? "error_class" : "input_class custum_input"
            }  toggle_input`}
            prefix={
              <Toggle
                active={active}
                setActive={setActive}
                onSelect={onSelect}
                editCondition={editCondition}
              />
            }
            style={{
              height: "40px",
              marginBottom: "24px",
            }}
            value={mobMargin}
            onChange={handleMobMargin}
            onKeyPress={handleKeyPress}
          />
          {errorMessage && (
            <Text
              style={{
                position: "absolute",
                width: "100%",
                bottom: 0,
                left: 0,
                fontSize: "12px",
                color: "#c13615",
              }}
            >
              {active === 0
                ? `You can add between ₹ 0 - ₹ 
                ${
                  record?.after_tax ? Number(record?.after_tax)?.toFixed(2) : 0
                } margin`
                : `You
                can add between 0 % - 100 %`}
            </Text>
          )}
        </Col>
        <MOBButton
          onClick={() => {
            setOpen(false);
            handleSaveMobMargin();
          }}
          icon={<CheckIcon />}
        >
          Add margin
        </MOBButton>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <GSTContainer>
        <GstInput
          className="custum_input"
          value={`${gst}`}
          // value={`${gst}%`}
          onChange={handleGst}
          onPressEnter={handleSave}
          onBlur={handleSave}
          suffix={"%"}
        />
        <AfterTaxText level={5}>
          ₹ {priceAfterTax(record).toFixed(2)}
        </AfterTaxText>
        <GstInput
          className={`${
            discountError ? "discount_error_input" : "custum_input"
          }`}
          value={`₹ ${discount}`}
          onChange={(e) => handleDiscount(e)}
          onPressEnter={handleSave}
          onBlur={handleSave}
        />
        <GstInput
          className="custum_input"
          value={quantity}
          onChange={handleQuantity}
          onPressEnter={handleSave}
          onBlur={handleSave}
        />
      </GSTContainer>
      <RowDropDownContainer className="custom_select">
        <Dropdown
          overlay={renderDropdownOptions()}
          trigger={["click"]}
          visible={open}
          onVisibleChange={handleOpenChange}
        >
          {record?.amount ? (
            <MobDropDownContainer>
              <MobDropDownAddedText>
                MOB margin:{" "}
                {active === 0
                  ? `₹ ${record?.amount || ""}`
                  : `${record?.amount|| ""}%`}
              </MobDropDownAddedText>
              <Col style={{ height: "27px" }}>
                {open ? <SelectDownIcon /> : <SelectDownIcon />}
              </Col>
            </MobDropDownContainer>
          ) : (
            <MobDropDownContainer
              style={{
                backgroundColor: "#e8ecff",
              }}
            >
              <MobDropDownAddedText
                style={{
                  color: "#3c51be",
                }}
              >
                Add MOB margin : 0%
              </MobDropDownAddedText>
              <Col style={{ height: "27px" }}>
                {open ? <PurpleDownIcon /> : <PurpleDownIcon />}
              </Col>
            </MobDropDownContainer>
          )}
        </Dropdown>
        <BMPPrice
          style={{
            color: record?.amount ? "#0a243f" : "#b2b2b2",
          }}
        >
          BMP price:{" "}
          {record?.bmp_price
            ? `₹ ${parseFloat(record?.bmp_price).toFixed(2)}`
            : "₹ 0"}
        </BMPPrice>
      </RowDropDownContainer>
    </>
  );
};
export default GstCell;
