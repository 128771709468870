import { Avatar, Button, Col, Row, Typography } from "antd";
import React,{useEffect} from "react";
import { useNavigate,useParams } from "react-router-dom";
import {
  // DownOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import "./PersonalInfo.css";
import { ReactComponent as VerifyIcon } from "../../../assets/icons/verify.svg";
import {
  useLazyGetCustomerDetailQuery,
} from "../../../apis/customer";
const { Text } = Typography;
const PersonalInfo = () => {
  const navigate = useNavigate();
  const handleBackNavigate = () => [navigate(-1)];
  const param = useParams();

  const [customerDetailApi, {data: customerDetails}] =  
  useLazyGetCustomerDetailQuery();
  // console.log("🚀 ~ PersonalInfo ~ customerDetails:", customerDetails)
  useEffect(() => {
    if (param) {
      customerDetailApi(param?.phone_number);
    }  
  }, [param,customerDetailApi]);

  return (
    <Row className="customerOrderContainer">
      <Button
        className="btn"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackNavigate}
      >
        BACK
      </Button>
      <Row className="myAccountMainTableHeadingParent">
        <Row className="myAccountHeadingTitleParent">
          <Text className="myAccountTableTitle">Personal Info</Text>
        </Row>
      </Row>
      <Col className="customer-personal-info">
        <Col className="customer-personal-info-I">
          <Text className="customer-personal-info-header">Profile</Text>
          <Col className="customer-personal-info-prof">
            <Avatar src={customerDetails?.data?.profile_picture} size={80} />
            {/* <Button type="primary" className="customer-personal-info-upload">
              Upload new picture
            </Button>
            <Button className="customer-personal-info-del">Delete</Button> */}
          </Col>
          <Col className="customer-personal-info-name">
            <Col className="customer-personal-info-align">
              <Text className="customer-personal-info-label">Full name</Text>
              <Text className="customer-personal-info-value">{customerDetails?.data?.full_name}</Text>
            </Col>
            {/* <Button className="customer-personal-info-del">Edit</Button> */}
          </Col>
        </Col>
        <Col className="customer-personal-info-II">
          <Col className="customer-personal-info-align">
            <Text className="customer-personal-info-label">Email address</Text>
            <Text className="customer-personal-info-value">
            {customerDetails?.data?.email}
            </Text>
          </Col>
          <Col style={{ display: "flex", gap: "24px", alignItems: "center" }}>
            {/* <Text className="customer-personal-info-textI">
              For extra security verify your email
            </Text>
            <Button className="customer-personal-info-del">Verify now</Button> */}
          </Col>
        </Col>
        <Col className="customer-personal-info-III">
          <Col className="customer-personal-info-align">
            <Text className="customer-personal-info-label">Phone number</Text>
            <Col style={{ display: "flex", alignItems: "center", gap: "24px" }}>
              <Text className="customer-personal-info-value"> {customerDetails?.data?.phone_number}</Text>
              <Col className="verify-align">
                <VerifyIcon />
                <Text style={{ color: "#6c7c8c", fontWeight: 500 }}>
                  Verified
                </Text>
              </Col>
            </Col>
          </Col>
        </Col>
      </Col>
      {/* <Col className="customer-personal-info-password">
        <Text className="customer-personal-info-passLab">Your password</Text>
        <Col className="customer-personal-info-name">
          <Col className="customer-personal-info-align">
            <Text className="customer-personal-info-label">Password</Text>
            <Col style={{ display: "flex", gap: "8px" }}>
              {[...Array(10)].map((_, index) => (
                <Text key={index} className="customer-personal-info-dot" />
              ))}
            </Col>
          </Col>
          <Button className="customer-personal-info-del">Password</Button>
        </Col>
      </Col> */}
    </Row>
  );
};

export default PersonalInfo;
