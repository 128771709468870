import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Image as AntImage,
  Avatar,
  Modal,
  Image,
} from "antd";
import { ReactComponent as MapIcon } from "../../../assets/icons/map.svg";
import { ReactComponent as OrderIcon } from "../../../assets/icons/orders_icon.svg";
import { ReactComponent as RFQIcon } from "../../../assets/icons/rfq.svg";
import NoRecordsIcon  from "../../../assets/icons/cart-norecords.webp";

import EditProjectAddressModal from "./EditProjectAddressModal";
import {

  getNameInitials,

} from "../../../commonFuntions/CommonUtilFunctions";
import { Link } from "react-router-dom";

const Text = Typography;
const ProjectListView = ({
  projectData,
  isFetching,
  currPageToShow,
  setCurrPageToShow,
  setIsModalVisible,
  isModalVisible,
  footer,
  handleUserAddressCreation,
  setuserAddressDetails,
  userAddressDetails,
  isSubmitClick,
  handleCancel,
  EditAddressDetails,
  cityDisabled,
  setcityDisabled,
  setstateDisabled,
  stateDisabled,
  setFile,
  setFileSize,
  isMobile,
  file,
  seller_id
}) => {
  return (
    <>
      <Row className="mt-4 width-100" gutter={[16, 16]}>
      {projectData?.results?.length > 0 ? (
      <>
        {projectData?.results.map((project) => (
          <Col xs={24} sm={8} md={8} lg={8} xl={8} key={project.project_id}>
            <Card className="card-projects">
              <Row gutter={[16, 16]}>
                <Col className="project-icon-cell">
                  {project?.project_image !== null ? (
                    <AntImage
                      src={project?.project_image}
                      className="project-icon-image"
                      width={48}
                      height={48}
                    />
                  ) : (
                    <Avatar className="project-header-avatar" size="large">
                     {!!project?.project_name &&
                      getNameInitials(project?.project_name)}
                    </Avatar>
                  )}
                </Col>
                <Col>
                  <Text className="card-project-light">
                    {" "}
                    MOB Project ID: {project.project_id}
                  </Text>
                  <Text className="card-project-heading">
                    {" "}
                    {project.project_name}
                  </Text>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex gap-8 justify-content-center">
                  <Link to={`/admin/customer/customerDetails/customerOrders/${seller_id}?project_name=${encodeURIComponent(project.project_name)}`} className="hyperlink-text d-flex gap-8 justify-content-center">
                    <OrderIcon />
                    {project.total_orders} orders
                  </Link>
                  <Link  to={`/admin/customer/customerDetails/customerQuotation/${seller_id}?project_name=${encodeURIComponent(project.project_name)}`} className="hyperlink-text d-flex gap-8 justify-content-center">
                    <RFQIcon />
                    {project.total_rfq} RFQ’s
                  </Link>
                </Col>
              </Row>
              <Row className="card-project-address fixed-height">
                <Col className="d-flex justify-content-space width-100">
                  <p className="card-project-text">Delivery address</p>
                  <p
                    className="hyperlink-text cursor-pointer"
                    onClick={() => {
                      EditAddressDetails(project);
                      setIsModalVisible(true);
                    }}
                  >
                    Edit
                  </p>
                </Col>
                <Col span={24}>
                  <Text className="card-project-textlight addres-line-fix">
                  <span className="card-project-text">{project.site_delivery?.name}</span><br/>
                    {project.site_delivery?.address_line_1}{" "}
                    {project.site_delivery?.address_line_2}{" "}
                    {project.site_delivery?.city} {project.site_delivery?.state}
                    {"-"} {project.site_delivery?.pincode}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text className="card-project-textlight ">
                    <span className="card-project-text">
                      {" "}
                      Site person name:
                    </span>{" "}
                    {project.site_delivery?.site_person ? project.site_delivery.site_person : "NA"} 
                  </Text>
                  <Text className="card-project-textlight">
                    <span className="card-project-text">Ph no: </span>
                   
                    {project.site_delivery?.site_person_mobile ? project.site_delivery.site_person_mobile : "NA"}
                  </Text>
                </Col>
                <Col className="map-container" span={24}>
                  <Text className="map-wrapper">
                    <MapIcon />
                    <a
                      className="hyperlink-text"
                      href={project?.site_delivery?.google_map_link || "#"} // Fallback to '#' if link is not available
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        if (!project?.site_delivery?.google_map_link) {
                          e.preventDefault(); // Prevent the default action of the link
                          EditAddressDetails(project); // Call the function to edit address details
                          setIsModalVisible(true); // Show the modal
                        }
                      }}
                    >
                      {project?.site_delivery?.google_map_link
                        ? "Google map view"
                        : "Add map link"}
                    </a>
                  </Text>
                </Col>
              </Row>
              <Row></Row>
            </Card>
          </Col>
        ))}
        </>
  ) : (
    <Row className="no-result-found-container width-100">
      <Image
      preview={false}
              src={NoRecordsIcon}
              alt=""            
              className=""
              width={200} height={200}
            />
    {/* <AntImage src={noImageFound} alt="noImageFound" width={224} height={224} /> */}
    <Text className="no-result-found-container-txt">
    No projects found!
    </Text>{" "}
    <Text className="no-result-found-container-txt-please-try">
    Oops! We can’t find what you’re looking for.
    </Text>{" "}
  </Row>
  )}
      </Row>
      <Modal
        title="Edit project details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={footer}
        className="editAddressInfoModalParent"
      >
        <EditProjectAddressModal
          // isMobile={isMobile}
          IsModalVisible={isModalVisible}
          setuserAddressDetails={setuserAddressDetails}
          userAddressDetails={userAddressDetails}
          handleUserAddressCreation={handleUserAddressCreation}
          handleCancel={handleCancel}
          setFile={setFile}
          setFileSize={setFileSize}
          // addAddressFlag={addAddressFlag}
          // SetaddAddressFlag={SetaddAddressFlag}
          isSubmitClick={isSubmitClick}
          // creatingAddress={creatingAddress}
          // setAddressType={setAddressType}
          cityDisabled={cityDisabled}
          setcityDisabled={setcityDisabled}
          stateDisabled={stateDisabled}
          setstateDisabled={setstateDisabled}
          file={file}
        />
      </Modal>
    </>
  );
};

export default ProjectListView;
