import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Select,
  Skeleton,
  Typography,
  Col,
  Button,
  notification,
} from "antd";

import React, { useState } from "react";
// eslint-disable-next-line
import {
  // eslint-disable-next-line
  stateOptions,
  cityOptions,
  checkEmptyValidation,
  checkPhoneNumberValidation,
  checkgstOptionalValidation,
} from "../../../commonUtils/commonUtils";
import { useCreateProjectMutation } from "../../../apis/ManageOrders";

import GridviewIcon from "../../../assets/icons/gridview.svg";
import TableIcon from "../../../assets/icons/table.svg";
import AddNewProjectAddressModal from "./AddNewProjectAddressModal";

const ProjectNavbar = ({
  isFetching,
  projectDetailsData,
  orderSortBy,
  setOrderSortBy,
  setOrderInpTxt,
  orderInpTxt,
  handleInputChange,
  setViewType,
  viewType,
  getProjectDetailApi,
  seller_id,
  
}) => {
  const { Text } = Typography;
  const { Option } = Select;
  // const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userAddressDetails, setuserAddressDetails] = useState({});
  const [file, setFile] = useState("");
  // eslint-disable-next-line
  const [fileSize, setFileSize] = useState("");
  const [addAddressFlag, SetaddAddressFlag] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  // eslint-disable-next-line
  const [addressType, setAddressType] = useState("");
  const [cityDisabled, setcityDisabled] = useState(false);
  const [stateDisabled, setstateDisabled] = useState(false);

  const [
    createProject,
    {
      // eslint-disable-next-line
      data: createAddressResp,
      // eslint-disable-next-line
      isSuccess: createdUserAddressSuccess,
      isLoading: creatingAddress,
    },
  ] = useCreateProjectMutation();

  const viewOptions = [
    { value: "table", label: "Table View", icon: TableIcon },
    { value: "grid", label: "Grid View", icon: GridviewIcon },
  ];
  const handleChange = (value) => {
    setViewType(value);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setuserAddressDetails({});
    setIsModalVisible(false);
  };
  const handleUserAddressCreation = async () => {
    let response;
    if (addAddressFlag) {
      if (
        checkEmptyValidation(userAddressDetails?.name) &&
        checkEmptyValidation(userAddressDetails?.state) &&
        checkEmptyValidation(userAddressDetails?.city) &&
        checkPhoneNumberValidation(userAddressDetails?.phone_number) &&
        checkgstOptionalValidation(userAddressDetails?.gst_number)
      ) {
        if (userAddressDetails) {
          const siteDeliveryData = {
            name: userAddressDetails.name,
            phone_number: userAddressDetails.phone_number,
            email: userAddressDetails.email,
            address_line_1: userAddressDetails.address_line_1,
            address_line_2: userAddressDetails.address_line_2,
            city: userAddressDetails.city,
            state: userAddressDetails.state,
            pincode: userAddressDetails.pincode,
            gst_number: userAddressDetails.gst_number,
            google_map_link: userAddressDetails.google_map_link,
            site_person: userAddressDetails.site_person,
            site_person_mobile: userAddressDetails.site_person_mobile,
          };
          const body = {
            project_name: userAddressDetails?.project_name,
            city: userAddressDetails?.Projectcity,
            site_delivery: addAddressFlag
              ? undefined
              : userAddressDetails?.site_delivery,
            address: addAddressFlag ? siteDeliveryData : undefined,
            project_image: file?.originFileObj,
          };

          response = await createProject({
            id: seller_id,
            body: body,
          });
          if (response?.data?.status) {
            setIsModalVisible(false);
            setFile("");
            setFileSize("");
            setuserAddressDetails({});
            setIsSubmitClick(false);
            getProjectDetailApi({
              ...(seller_id && { id: seller_id }),
              params: { page: 1 },
            });
          }
        }
      } else {
        setIsSubmitClick(true);
      }
    } else {
      if (
        checkEmptyValidation(userAddressDetails?.project_name) &&
        checkEmptyValidation(userAddressDetails?.Projectcity) &&
        userAddressDetails?.site_delivery
      ) {
        if (userAddressDetails) {
          const siteDeliveryData = {
            name: userAddressDetails.name,
            phone_number: userAddressDetails.phone_number,
            email: userAddressDetails.email,
            address_line_1: userAddressDetails.address_line_1,
            address_line_2: userAddressDetails.address_line_2,
            city: userAddressDetails.city,
            state: userAddressDetails.state,
            pincode: userAddressDetails.pincode,
            gst_number: userAddressDetails.gst_number,
            google_map_link: userAddressDetails.google_map_link,
            site_person: userAddressDetails.site_person,
            site_person_mobile: userAddressDetails.site_person_mobile,
          };
          const body = {
            project_name: userAddressDetails?.project_name,
            city: userAddressDetails?.Projectcity,
            site_delivery: addAddressFlag
              ? undefined
              : userAddressDetails?.site_delivery,
            address: addAddressFlag ? siteDeliveryData : undefined,
            project_image: file?.originFileObj,
          };

          response = await createProject({
            id: seller_id,
            body: body,
          });
          if (response?.data?.status) {
            setIsModalVisible(false);
            getProjectDetailApi({
              ...(seller_id && { id: seller_id }),
              params: { page: 1 },
            });
            setuserAddressDetails({});
            setFile("");
            setcityDisabled(false);
            setstateDisabled(false);
          }
        }
      } else {
        setIsSubmitClick(true);
        if (!userAddressDetails?.site_delivery) {
          notification.error({
            message: "",
            description: "Please select a address",
            duration: 3, // Duration in seconds before the notification closes
          });
        }
      }
    }
  };
  const footer = (
    <Row className="editAddressFooterParent">
      <Button
        className="editAddressFooterButton"
        onClick={() => {
          // dispatch(updateOpenAddAddressModal(false));
          setIsSubmitClick(false);
          setuserAddressDetails({});
          setIsModalVisible(false);
          setFile("");
          setcityDisabled(false);
          setstateDisabled(false);
        }}
      >
        CANCEL
      </Button>

      <Button
        // loading={creatingAddress}
        className="editAddressFooterButton editFooterSaveButton"
        onClick={handleUserAddressCreation}
      >
        CREATE NEW PROJECT
      </Button>
    </Row>
  );

  return (
    <>
      <Row className="myAccountMainTableHeadingParent width-100">
        <Col span={24} className="project-heading">
          <Text className="myAccountTableTitle">Projects</Text>
          <Text className="myAccountTableTotalItems">
            {isFetching ? (
              <Skeleton.Input className="order-details-skeleton" />
            ) : (
              `${projectDetailsData?.pagination?.total_entries || 0} items`
            )}
          </Text>
        </Col>

        <Row gutter={[16, 16]} className="width-100">
          <Col
            className=" d-flex gap-8"
            xs={24}
            sm={20}
            md={20}
            lg={20}
            xl={12}
          >
            <Input
              placeholder="Search by project name/ city/ address"
              className="projectsearch custom-height"
              value={orderInpTxt}
              onChange={(e) => handleInputChange(e)}
              // suffix={
              //   <Image src={SearchIcon} height={20} width={20} alt="SearchIcon" />
              // }
            />
          </Col>
          <Col
            xs={12}
            sm={20}
            md={20}
            lg={20}
            xl={4}
            className="custom-dropdown"
          >
            {/* <Text className="quotation-req-sort-by-txt"> Sort Order:</Text> */}
            <Select
              suffixIcon={
                !orderSortBy ? (
                  <DownOutlined />
                ) : (
                  <CloseOutlined
                    onClick={(val) => {
                     setOrderSortBy(val.value);
                    }}
                  />
                )
              }
              showSearch
              value={orderSortBy}
              style={{ minWidth: "180px" }}
              placeholder={"City"}
              onChange={(e, val) => {
                setOrderSortBy(val.value);
                setOrderInpTxt("");
              }}
            >
              <Option disabled value="">
                City
              </Option>
              {cityOptions?.sort((a, b) => a.value.localeCompare(b.value))?.map((option) => (
                <Option key={option.key} value={option?.value}>
                  {option?.value}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            xs={12}
            sm={20}
            md={20}
            lg={20}
            xl={4}
            className="custom-dropdown"
          >
            <Select
              style={{ minWidth: "180px" }}
              value={viewType}
              onChange={handleChange}
            >
              {viewOptions.map((option) => (
                <option
                  className="filter-icon"
                  key={option.value}
                  value={option.value}
                >
                  <img src={option.icon} alt=""></img>
                  {option.label}
                </option>
              ))}
            </Select>
          </Col>

          <Col xs={24} sm={20} md={20} lg={20} xl={4}>
            <Button onClick={showModal} className="project-info-upload-btn">
              {/* <Image alt="plusIcon" src={plusIcon} width={18} height={18} /> */}
              Create new project
            </Button>
          </Col>
        </Row>
      </Row>

      <AddNewProjectAddressModal
        IsModalVisible={isModalVisible}
        setuserAddressDetails={setuserAddressDetails}
        userAddressDetails={userAddressDetails}
        handleUserAddressCreation={handleUserAddressCreation}
        handleCancel={handleCancel}
        setFile={setFile}
        setFileSize={setFileSize}
        addAddressFlag={addAddressFlag}
        SetaddAddressFlag={SetaddAddressFlag}
        isSubmitClick={isSubmitClick}
        creatingAddress={creatingAddress}
        setAddressType={setAddressType}
        cityDisabled={cityDisabled}
        setcityDisabled={setcityDisabled}
        stateDisabled={stateDisabled}
        setstateDisabled={setstateDisabled}
        footer={footer}
        seller_id={seller_id}
        file={file}

      />
    </>
  );
};

export default ProjectNavbar;
