import React, { useEffect, useState } from "react";
import "./RFQDetails.css";
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { ReactComponent as Document } from "../../../assets/icons/documents.svg";
import { ReactComponent as CustmerViewVipIcon } from "../../../assets/icons/custmerViewVip.svg";
import { ReactComponent as ProProfileIcon } from "../../../assets/icons/proProfileIcon.svg";
import {
  getAcceptedConvertedOrderQuote,
  getPublishedUnpublishedQuote,
  getRFQListDetailsById,
  getRFQListFlag,
  
} from "../../../redux/slices/rfqList/action";
import {
  acceptedConvertedOrderSelctor,
  publishedUnpublishedSelctor,
  rfqListDataSelctor,
  rfqListFlagSelector,
} from "../../../redux/slices/rfqList/selector";
import {
  colorMapping,
  dateFormat,
} from "../../../commonFuntions/CommonUtilFunctions";
import {
  ArrowLeftOutlined,
  // FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  // useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useGetRFQListDetailsByIdQuery,useUpdateRfqStatusMutation } from "../../../apis/rfqList";
import RFQquote from "./RFQquotes";
import StatusBar from "./StatusBar";
import { LayoutWrapper } from "../../../components/LayoutWrapper/LayoutWrapper";
import {
  getBillingAddress,
  getDeliveryAddress,
  getRFQQuoteData,
} from "../../../redux/slices/createQuote/action";
import RequestDetailsByCustmer from "../../../components/RequestDetailsByCustomer/RequestDetailsByCustmer";
import { rupifiStatusCheck } from "../../../commonUtils/commonUtils";
import MobCreditCard from "../../../components/CustomContainer/CustomProFileSection/MobCreditCard";
import CreateMobAddressBanner from "./CreateMobAddressBanner";
import MobCreditAddressModal from "../../../components/Modal/MobCreditAddressModal/MobCreditAddressModal";
import {
  ActivityContainer,
  ActivityText,

} from  "../RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";
import { ReactComponent as RedundantIcon } from "../../../assets/icons/redundant.svg" ;
import UpdateRedundantModal from "../../../components/Modal/UpdateRedundantModal/UpdateRedundantModal";
import { showSuccessToast } from "../../../NotificationToast/NotificationToast";
import ReportingCard from '../../../components/Reporting/ReportingCard';
const { Text, Title } = Typography;

const RFQDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [redundantData, setRedundantData] = useState({});

  const listFlag = useSelector(rfqListFlagSelector);
  const {
    data: rfqData,
    refetch: rfqListRefetch,
    isLoading,
  } = useGetRFQListDetailsByIdQuery(params?.id);
    
  const [updateStatuApi] = useUpdateRfqStatusMutation();

  
  const listData = useSelector(rfqListDataSelctor);

  const convertedAcceptedQuoteList = useSelector(acceptedConvertedOrderSelctor);
  const publishUnpublishedQuoteList = useSelector(publishedUnpublishedSelctor);
  const {
    rfq_id,
    rfq_status,
    created_at,
    rfq_created_by,
     quotes,
     is_redundant
  } = listData;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleRefech = () => {
    rfqListRefetch(params?.id);
  };
  useEffect(() => {
 
    if (rfqData) {
      var reportingManager = Array.isArray(rfqData.data.reporting_managers) 
      ? rfqData.data.reporting_managers 
      : [];
      const transformedResponse = reportingManager.map(item => ({
        label: item.reporting_person,
        key: item.id.toString()
    }));


      setReportingManager(transformedResponse )
      

      setRedundantData(
        {
          "reason_for_redundant" : rfqData?.data?.reason_for_redundant,
          "additional_comment"   : rfqData?.data?.additional_comment,
        }
      )

      dispatch(getRFQListDetailsById(rfqData?.data));
      // dispatch(getRFQListDetailsById(rfqData.data.results[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqData]);
  useEffect(() => {
    dispatch(getRFQQuoteData({}));
    dispatch(getDeliveryAddress({}));
    dispatch(getBillingAddress({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (listFlag) {
      rfqListRefetch(params?.id);
      dispatch(getRFQListFlag(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listFlag]);

  useEffect(() => {
    if (listData?.quotes) {
      const convertedOrderQuote = [];
      const publishedQuote = [];
      quotes?.map((item) => {
        if (
          item?.quote_status === "Unpublished" ||
          item?.quote_status === "Published" ||
          item?.quote_status === "Rejected"
        ) {
          publishedQuote.push(item);
        } else if (
          item?.quote_status === "Accepted" ||
          item?.quote_status === "Order Created" ||
          item?.quote_status === "Order Converted"
        ) {
          convertedOrderQuote.push(item);
        }
        return null;
      });
      dispatch(getAcceptedConvertedOrderQuote(convertedOrderQuote));
      dispatch(getPublishedUnpublishedQuote(publishedQuote));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);
  const handleCustomerView = (id) => {
    navigate(`/admin/customer/customerDetails/${id}`, {
      state: { id: `/admin/rfq/quotes/${rfq_id}` },
    });
  };
  const createQuoteNavigation = () => {
    navigate(`/admin/rfq/quotes/create-quote/${params?.id}`, {
      state: { id: params?.id },
    });
    // navigate(`/admin/rfq/quotes/createQuote/${id}`, { state: { id } });
  };
  function isAcceptedPresent(givenArray = [], key) {
    for (let i = 0; i < givenArray.length; i++) {
      if (givenArray?.length === 0) {
        return false;
      }
      if (givenArray[i][key] === "Accepted") {
        return true;
      }
    }
    return false;
  }

  const conditionAccepted = isAcceptedPresent(
    convertedAcceptedQuoteList,
    "quote_status"
  );

  const showRedundantModal = () => {
    setIsModalOpen(true);
  };

  const hideRedundantModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveApi = async () => {
    const data = {
      rfq_id: params?.id,
      is_redundant : true,
      reason_for_redundant :  redundantData?.reason_for_redundant,
      additional_comment : redundantData?.additional_comment
    };
    let response;
    response = await updateStatuApi(data);
    showSuccessToast(response?.data?.message);  
    hideRedundantModal(); 
    rfqListRefetch(params?.id);
    
  };

  const handleRedundantChange = (value) =>{
    setRedundantData((prevData) => ({
      ...prevData, 
      reason_for_redundant: value, 
    }));
  }

  const handleTextChange = (e) => {
    console.log(e.target.value)
    setRedundantData((prevData) => ({
      ...prevData, 
      additional_comment: e.target.value, 
    }));
  };

  const [open, setOpen] = useState(false);
  const [reportingManager, setReportingManager] = useState([])
  
  const handleMenuClick = async (e) => {
    const data = {
      rfq_id: params?.id,
      reporting_manager : e.key
    };
    let response;
    response = await updateStatuApi(data);
    showSuccessToast(response?.data?.message);  
    hideRedundantModal(); 
    rfqListRefetch(params?.id);
  };
  
  const handleOpenChange = (nextOpen, info) => {    
    setOpen(nextOpen);
   
  };
 

  return (
    <LayoutWrapper>
      <Button
        icon={<ArrowLeftOutlined style={{ fontWeight: 500 }} />}
        onClick={() => navigate(`/admin/rfq`)}
        style={{
          height: "48px",
          width: "175px",
          fontWeight: 500,
          fontSize: "14px",
          border: "solid 1.5px #dedede !important",
        }}
      >
        BACK TO RFQ’s
      </Button>
     
      {rfq_status !== "Order Created" && (
  <div>
 {isLoading  ? (
       <Skeleton
       active
       paragraph={{
         rows: 2,
       }}
     />
      ) : (
        <>
       {!is_redundant &&
        (
        <ActivityContainer onClick={showRedundantModal}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
            <RedundantIcon style={{ height: "20px", width: "20px", marginRight: "8px" }} />
            <ActivityText level={5}>Redundant/lost</ActivityText>
          </div>
        </ActivityContainer>
      )}
      </>
      )}
  </div>
)}


     
      
      {/* <ActivityContainer onClick={showRedundantModal}>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
    <RedundantIcon style={{ height: "20px", width: "20px", marginRight: "8px" }} />
    <ActivityText level={5}>Redundant/lost</ActivityText>
  </div>
</ActivityContainer> */}


        
        <UpdateRedundantModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleSaveApi={handleSaveApi}
        loading={false} 
        handleRedundantChange={handleRedundantChange}
        redundantData={redundantData}
        handleTextChange={handleTextChange}
      />

      {isLoading ? (
        <Skeleton
          active
          paragraph={{
            rows: 2,
          }}
        />
      ) : (
       
        <Row style={{ marginBottom: "1.5rem" }}>
          <Col
            span={8}
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Title level={2}>{rfq_id}</Title>
              {is_redundant ? (
                <Tag
                style={{
                  // width: "105px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  fontSize: " 14px",
                  margin: "0.7rem 0 0 1.5rem",
                }}
                className={`tag-font-style colorDanger`}
              >
                  Redundant/lost
              </Tag>
               
              ) : (
                <Tag
                style={{
                  // width: "105px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  fontSize: " 14px",
                  margin: "0.7rem 0 0 1.5rem",
                }}
                className={`tag-font-style ${colorMapping[rfq_status]}`}
              >
                {rfq_status}
              </Tag>
              )}
              
              
            </Col>
            <Col>
              <Text
                style={{
                  fontSize: " 16px",

                  lineHeight: 1.5,
                }}
              >
                {created_at && dateFormat(created_at)}
              </Text>
            </Col>
            {
              rfqData?.data?.quotes && rfqData?.data?.quotes.length > 0 &&(
            <Col  style={{                 
                  marginTop:"8px",
                  lineHeight: 1.5,
                }}>
            <Text className="card-project-text project-tag"> Project: {rfqData?.data?.quotes[0]?.delivery_address?.project ? rfqData?.data?.quotes[0]?.delivery_address?.project?.project_name : <span className="text-danger-na">NA</span>}</Text>
            </Col>
            )
          }
          </Col>
          {rfq_status === "Order Created" || is_redundant ? (
            <></>
          ) : (
            <Col
              span={8}
              offset={8}
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "16px",
                justifyContent: "flex-end",
              }}
            >
              
              {/* <Button
                style={{
                  height: "48px",
                  width: "153px",
                  fontWeight: 500,
                  fontSize: "14px",
                  border: "solid 1.5px #dedede !important",
                }}
              >
                CREATE ORDER
              </Button>{" "} */}
              <Button
                style={{
                  height: "48px",
                  width: "153px",
                  fontWeight: 500,
                  fontSize: "14px",
                  border: "solid 1.5px #dedede !important",
                  color: "white",
                  backgroundColor: "#0354a3"
                  // backgroundColor:
                  //   !listData?.have_credit_address &&
                  //   rfq_created_by?.rupifiDetails &&
                  //   Object.keys(rfq_created_by?.rupifiDetails || {})?.length
                  //     ? "#9da7b2"
                  //     : "#0354a3",
                }}
                onClick={() => createQuoteNavigation()}
                // disabled={
                //   !listData?.have_credit_address &&
                //   rfq_created_by?.rupifiDetails &&
                //   Object.keys(rfq_created_by?.rupifiDetails || {})?.length
                // }
              >
                CREATE QUOTE
              </Button>
            </Col>
          )}
        </Row>
      )}
      {!isLoading &&
        !listData?.have_credit_address &&
        rfq_created_by?.rupifiDetails &&
        Object.keys(rfq_created_by?.rupifiDetails || {})?.length !== 0 && rfq_created_by?.rupifiDetails?.account_status !==null && (
          <>
            <Row className="create-mob-credit-container">
              <CreateMobAddressBanner handleClick={showModal} />
            </Row>
            <MobCreditAddressModal
              isModalOpen={isModalOpen}
              setModalClose={setIsModalOpen}
              data={listData?.rfq_created_by}
              handleRefech={handleRefech}
            />
          </>
        )}
        
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col style={{ width: "60%" }}>
          {isLoading ? (
            <Skeleton
              style={{ marginTop: "20px" }}
              active
              avatar
              paragraph={{
                rows: 2,
              }}
            />
          ) : (
            <Col style={{ marginBottom: "20px" }}>
              {rfq_status === "Order Created" && (
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "52px",
                    padding: "16px",
                    borderRadius: "8px",
                    backgroundColor: "#e9fdf2",
                    color: "#0a243f",
                    fontWeight: 500,
                  }}
                >
                  <Text style={{ fontSize: "12px", fontWeight: 500 }}>
                    🎉 Order has been created for this request. Please click on
                    view details to check the order status or edit
                  </Text>
                </Col>
              )}
              <RFQquote
                quotationList={convertedAcceptedQuoteList}
                rfq_status={rfq_status}
                id={params?.id}
                conditionAccepted={conditionAccepted}
                data={listData}
                auto_generated={rfqData?.data?.auto_generated}
              />
            </Col>
          )}
          <Col>
            {isLoading ? (
              <Skeleton
                style={{ marginTop: "20px" }}
                avatar
                active
                paragraph={{
                  rows: 6,
                }}
              />
            ) : (
              <RequestDetailsByCustmer listData={listData} />
            )}
          </Col>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "3rem",
              gap: "1rem",
            }}
          >
            {publishUnpublishedQuoteList?.length > 0 && (
              <Col>
                <Title level={3} style={{ margin: 0 }}>
                  MOB Quotes
                </Title>
              </Col>
            )}
            {publishUnpublishedQuoteList?.length > 0 && (
              <StatusBar status={true} />
            )}
          </Row>
          {isLoading ? (
            <Skeleton
              avatar
              paragraph={{ rows: 4 }}
              active
              style={{ marginTop: "20px" }}
            />
          ) : (
            <>
              {quotes?.length <= 0 ? (
                <Row
                  style={{
                    maxWidth: "792px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    alignContent: "space-around",
                    marginTop: "3rem",
                    gap: "2rem",
                  }}
                >
                  <Col>
                    <Document />
                  </Col>
                  <Col>
                    <Title
                      level={4}
                      style={{
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      No quotes found!
                    </Title>
                    <Text style={{ textAlign: "center" }}>
                      please upload the quote for this request
                    </Text>
                  </Col>
                  <Col>
                    {" "}
                    <Button
                      style={{
                        height: "48px",
                        width: "153px",
                        fontWeight: 500,
                        fontSize: "14px",
                        border: "solid 1.5px #dedede !important",
                        color: "white",
                          backgroundColor: "#0354a3"
                        // backgroundColor:
                        //   !listData?.have_credit_address &&
                        //   rfq_created_by?.rupifiDetails &&
                        //   Object.keys(rfq_created_by?.rupifiDetails || {})
                        //     ?.length
                        //     ? "#9da7b2"
                        //     : "#0354a3",
                      }}
                      onClick={() => createQuoteNavigation()}
                      // disabled={
                      //   !listData?.have_credit_address &&
                      //   rfq_created_by?.rupifiDetails &&
                      //   Object.keys(rfq_created_by?.rupifiDetails || {})?.length
                      // }
                    >
                      CREATE QUOTE
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Col>
                  <RFQquote
                    quotationList={publishUnpublishedQuoteList}
                    rfq_status={rfq_status}
                    id={params?.id}
                    conditionAccepted={conditionAccepted}
                    auto_generated={rfqData?.data?.auto_generated}
                  />
                </Col>
              )}
            </>
          )}
        </Col>

        <Col style={{ marginTop: "20px", width: "35%" }}>
          {isLoading ? (
            <Skeleton
              active
              avatar
              paragraph={{
                rows: 4,
              }}
            />
          ) : (
            <>
              <Card
                style={{
                  width: "100%",
                  minHeight: "160px",
                  backgroundColor: "#f5f5f5",
                  position: "relative",
                }}
              >
                {rupifiStatusCheck.includes(
                  listData?.rfq_created_by?.rupifiDetails?.account_status
                ) && (
                  <Col style={{ position: "absolute", left: 0, top: "-32px" }}>
                    <CustmerViewVipIcon />
                  </Col>
                )}
                <Row>
                  <Col span={6}>
                    <Avatar
                      size={50}
                      style={{
                        border:
                          listData?.rfq_created_by?.rupifiDetails
                            ?.account_status === "ACTIVE"
                            ? "2px solid #fecb00"
                            : "1px solid transparent",
                      }}
                      icon={<UserOutlined />}
                    />
                  </Col>
                  <Col
                    span={18}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                    }}
                  >
                    <Title
                      level={5}
                      style={{
                        fontWeight: 500,
                        margin: 0,
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      {rfq_created_by?.full_name}
                      {rfq_created_by?.is_professional && <ProProfileIcon />}
                    </Title>
                    <Text style={{ fontWeight: 500 }}>
                      {rfq_created_by?.phone_number}
                    </Text>
                    <Text style={{ fontWeight: 500 }}>
                      {rfq_created_by?.email}
                    </Text>
                    <Text
                      style={{
                        fontWeight: 500,
                        color: "#2973f0",
                        paddingTop: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleCustomerView(rfq_created_by?.phone_number)
                      }
                    >
                      Go to customer view
                    </Text>
                  </Col>
                </Row>
              </Card>
              {rupifiStatusCheck.includes(
                listData?.rfq_created_by?.rupifiDetails?.account_status
              ) && (
                <Col style={{ marginTop: "20px" }}>
                  <MobCreditCard
                    ruipiCheck={
                      listData?.rfq_created_by?.rupifiDetails?.account_status==="ACTIVE"
                    }
                    approved_balance={
                      listData?.rfq_created_by?.rupifiDetails?.current_limit
                    }
                    available_balance={
                      listData?.rfq_created_by?.rupifiDetails?.balance
                    }
                    rupifyData={listData?.rfq_created_by?.rupifiDetails}
                  />
                </Col>
              )}
            </>
          )}

{isLoading ? (
  <>
    <Skeleton active style={{ width: '50%' }} />
  </>
) : (
 
  <ReportingCard
  reportingManager={listData?.reporting_manager}
  reportingManagers={reportingManager}
  open={open}
  handleMenuClick={handleMenuClick}
  handleOpenChange={handleOpenChange}
/>
)}

 
        </Col>
      </Row>
    </LayoutWrapper>
  );
};

export default RFQDetails;
