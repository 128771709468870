import React, { useEffect, useState } from "react";
import "./Sellers.css";
import {
  // PlusOutlined,
  // DownOutlined,
  DashOutlined,
} from "@ant-design/icons";

import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Tooltip,
  Typography,Tag
} from "antd";
import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import {
  useActivateTableMutation,
  useLazyGetSellerListQuery,
  useLazyGetDownloadSellerQuery
} from "../../../apis/sellerCatalogue";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditSellerVenderDetail,
  getSellerData,
} from "../../../redux/slices/sellerCatalogue/action";
import { sellerDataSelector } from "../../../redux/slices/sellerCatalogue/selector";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
// import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { Link, useNavigate } from "react-router-dom";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import StatusModal from "../../../components/Modal/StatusModal/StatusModal";
import { dateFormat, getNameInitials } from "../../../commonFuntions/CommonUtilFunctions";
import { baseUrl } from "../../../commonUtils/commonUtils";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
import { saveAs } from "file-saver";
import CustomSelect from "../../../components/SelectableDropdown/CustomSelect/CustomSelect";
const options = [
  {
    key: 1,
    value: "Excel",
    label: "Excel",
  },
];
const handleTableData = (data) => {  
  let tableData = [];
  data?.results?.map((item) => {
    tableData.push({
      ...item,
      vendor_name: item?.vendor_name,
      is_active: item?.is_active,
      bmp_id: item?.bmp_id,
      address: item?.address?.address,
      city: item?.city,
      phone_number: item?.phone_number,
      email: item?.email,
      shop_closing_time: item?.shop_closing_time,
      shop_opening_time: item?.shop_opening_time,
      id: item?.id,
      bmp_image: item?.bmp_image,      
      business_pan_card: item?.documents?.business_pan_card,
      gstin_certificate: item?.documents?.gstin_certificate,
      adhar_card: item?.documents?.adhar_card,
      adhar_card_2: item?.documents?.adhar_card_2,
      adhar_card_3: item?.documents?.adhar_card_3,
      adhar_card_4: item?.documents?.adhar_card_4,
      adhar_card_5: item?.documents?.adhar_card_5,
      owner_pan_card: item?.documents?.owner_pan_card,
      owner_pan_card_2: item?.documents?.owner_pan_card_2,
      owner_pan_card_3: item?.documents?.owner_pan_card_3,
      owner_pan_card_4: item?.documents?.owner_pan_card_4,
      owner_pan_card_5: item?.documents?.owner_pan_card_5,
    });
    return null;
  });
  return tableData;
};
const Sellers = () => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableData = useSelector(sellerDataSelector);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedValue, setSearchedValue] = useState({});
  const [tableActiveData, setTableActiveData] = useState("");
  
  const [sellerListApi, { data: sellerListData, isFetching: isLoading }] =    
    useLazyGetSellerListQuery();
    // console.log("🚀 ~ Sellers ~ sellerListData:", sellerListData)
  const [activateTableRowApi] = useActivateTableMutation();
  useEffect(() => {
    sellerListApi();
    dispatch(getEditSellerVenderDetail({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [
    downloadSellerApi,
    // { data }
  ] = useLazyGetDownloadSellerQuery();

  useEffect(() => {
    if (sellerListData?.status) {
      dispatch(getSellerData(sellerListData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerListData]);
  const handlePageChange = async (currPage) => {
    const params = {
      page: currPage,
      ...(searchedValue && { search: searchedValue?.search }),
    };
    setCurrentPage(currPage);
    await sellerListApi(params);
  };

  const handleChange = async (value) => {
    const params = {
    
      ...(searchedValue && { search: searchedValue?.search }),
    };
    const response = await downloadSellerApi(params);
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    } else {
      const responseData = response?.data;
      saveAs(`${baseUrl}${responseData?.data?.url}`);
      showSuccessToast(responseData?.message);
    }
  };

  const onActivate = async () => {
    const params = {
      mob_vendor_id: tableActiveData?.id,
      is_active: !tableActiveData?.is_active,
    };
    const response = await activateTableRowApi(params);
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setShowStatusModal(false);
      sellerListApi();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };

  const handleStatus = (record) => {
    setShowStatusModal(true);
    setTableActiveData(record);
  };
  const handleSearchedValue = (params) => {
    setSearchedValue(params);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (tableData) {
      const data = tableData ? handleTableData(tableData) : "";
      setTableDataSource(data);
    }
  }, [tableData]);
  // const handleNavigateSellerView = (id) => {
  //   navigate(`/admin/sellers/seller-view/${id}`);
  // };
  const handleNavigateAddSeller = () => {
    navigate(`/admin/sellers/add-seller`);
  };

  // const onEdit = (record) => {
  //   navigate(`/admin/sellers/add-seller/${record?.bmp_id}`);
  // };

  const columns = [
    {
      // title: (
      //   <PlusOutlined
      //     style={{
      //       backgroundColor: "#fff",
      //       borderRadius: "50%",
      //       padding: "3px",
      //     }}
      //   />
      // ),
      key: "icon",
      dataIndex: "icon",
      fixed: "left",
      width: "50px",
      render: (params, record) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      // onClick={() => onEdit(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Link
                        to={`/admin/sellers/add-seller/${record?.bmp_id}`}
                        target="_blank"
                      >
                        <Col className="container-list">
                          <Text className="select-text">Edit</Text>
                        </Col>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleStatus(record)}
                      style={{ padding: "7px 12px" }}
                    >
                      <Col className="container-list">
                        <Text className="select-text">
                          {record?.is_active ? "Deactivate" : "Activate"}
                        </Text>
                      </Col>
                    </Menu.Item>
                    {/* <Menu.Item
                      //   onClick={handleDeleteModalOpen}
                      style={{
                        padding: "7px 12px",
                      }}
                    >
                      <Col className="container-list">
                        <Text
                          className="select-text"
                          style={{ color: "#bf2600" }}
                        >
                          Delete
                        </Text>
                      </Col>
                    </Menu.Item> */}
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      fixed: "left",
      width: "393px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record.parentKey ? "60px" : "0px",
              }}
            >
              <Col>
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f1f1f2",
                  }}
                  shape="square"
                  src={`${baseUrl}${
                    record?.seller_display_image || record?.bmp_image
                  }`}
                  alt="Avatar"
                  // icon={<DefaultIcon width={20} height={20} />}
                >
                  {" "}
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    <Text
                      style={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {record?.vendor_name
                        ? getNameInitials(record?.vendor_name)
                        : `N/A`}
                    </Text>
                  </Col>
                </Avatar>
              </Col>
              <Col style={{ fontWeight: 500 }}>{record.vendor_name}</Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "BMP ID",
      dataIndex: "bmp_id",
      key: "bmp_id",
      width: "150px",
      render: (params, record) => {
        return (
          <>
            <Link
              to={`/admin/sellers/seller-view/${params}`}
              // onClick={() => handleNavigateSellerView(params)}
              target="_blank"
              style={{ color: "#2973f0", cursor: "pointer" }}
            >
              {params}
            </Link>
          </>
        );
      },
    },
    {
      title: "Date of Registration",
      dataIndex: "created_at",
      key: "created_at",
      width: "150px",
      render: (params, record) => {
        return (
          <>
           
           <Col>
            <Text>{params ? dateFormat(params) : ""}</Text>
          </Col>
           
          </>
        );
      },
    },
    {
      title: "Mode of Registration",
      dataIndex: "admin_onboarded",
      key: "admin_onboarded",
      width: "150px",
      render: (params, record) => {
        return (
          <>
           
           <Col>
        <Text>{params ? "Admin" : "Partner"}</Text>
      </Col>
           
          </>
        );
      },
    },
    {
      title: "mob Web Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "120px",
      render: (params, record) => {
        return <>{record?.is_active ? 
          <Tag color="green" className="tag-green" key={record.id}> Active </Tag> :
          <Tag color="red" className="tag-red" key={record.id}> Inactive </Tag>
        }</>;
      },
    },
    {
      title: "mobCredit Status",
      dataIndex: "mob_credit_enable",
      key: "mob_credit_enable",
      width: "120px",
      render: (params, record) => {      
        return <>{record?.mob_credit_enable ? 
          <Tag color="green" className="tag-green" key={record.id}> Active </Tag> :
          <Tag color="red" className="tag-red" key={record.id}> Inactive </Tag>
        }</>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
      render: (params) => {
        return (
          <>
            <Tooltip title={params} className="rfq-table-remarks" color="#fff">
              <Text style={{ cursor: "pointer" }}>
                {params?.length > 100
                  ? `${params?.slice(0, 40)}...`
                  : params || ""}
              </Text>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "120px",
    },
    {
      title: <Col style={{ textAlign: "left" }}>Business mobile</Col>,
      dataIndex: "phone_number",
      key: "phone_number",
      width: "180px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "250px",
    },
    {
      title: "Business GSTIN certificate",
      dataIndex: "gstin_certificate",
      key: "gstin_certificate",
      width: "200px",
      align: "center",
      render: (params, record) => {
        return (
          <>
          {params && (
          <a
            style={{ padding: "8px" }}
            className=""
            href={params}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
          )}
          </>
        );
      },
    },

    {
      title: "Business PAN card",
      dataIndex: "business_pan_card",
      key: "business_pan_card",
      width: "200px",
      align: "center",
      render: (params, record) => {
        return (
          <>
          {params && (
          <a
            style={{ padding: "8px" }}
            className=""
            href={params}
            target="_blank"
            rel="noreferrer"
          >
            View
          </a>
          )}
          </>
        );
      },
    },
    {
      title: "Owner/ Partner/ Director’s Aadhar",
      dataIndex: "",
      key: "Aadhar",
      width: "200px",
      align: "center",
      render: (parmas, record) => {
        return (
          <>
            {record?.adhar_card && (
              <a
                style={{ padding: "8px" }}
                className=""
                href={record?.adhar_card}
                target="_blank"
                rel="noreferrer"
              >
                View Upload 1
              </a>
            )}
            {record?.adhar_card_2 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_2}
                  target="_blank"
                  rel="noreferrer"
                >
                 View Upload 2
                </a>
              </>
            )}
            {record?.adhar_card_3 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_3}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 3
                </a>
              </>
            )}
            {record?.adhar_card_4 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_4}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 4
                </a>
              </>
            )}
            {record?.adhar_card_5 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.adhar_card_5}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 5
                </a>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Owner/ Partner/ Director’s PAN card",
      dataIndex: "",
      key: "Partner",
      width: "200px",
      align: "center",
      render: (parmas, record) => {
        return (
          <>
            {record?.owner_pan_card && (
              <a
                style={{ padding: "8px" }}
                className=""
                href={record?.owner_pan_card}
                target="_blank"
                rel="noreferrer"
              >
                View Upload 1
              </a>
            )}
            {record?.owner_pan_card_2 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_2}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 2
                </a>
              </>
            )}
            {record?.owner_pan_card_3 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_3}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 3
                </a>
              </>
            )}
            {record?.owner_pan_card_4 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_4}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 4
                </a>
              </>
            )}
            {record?.owner_pan_card_5 && (
              <>
                <br />
                <a
                  style={{ padding: "8px" }}
                  className=""
                  href={record?.owner_pan_card_5}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Upload 5
                </a>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Shop Time",
      dataIndex: "shop_opening_time",
      key: "shop_opening_time",
      width: "200px",
      render: (parms, record) => {
        return (
          <>
            <Text>
              {record?.shop_opening_time} - {record?.shop_closing_time}
            </Text>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Col style={{ padding: "2rem" }}>
        <Row>
          <Col className="header-container">
            <Text className="heading">Sellers</Text>
          </Col>
        </Row>
        <Row className="action-container">
          <Col className="action-sub-container">
            <SearchInput
              size={"large"}
              placeholder={"Search by shop name, mobile no, email"}
              getLibraryApi={sellerListApi}
              width={"400px"}
              isLoading={isLoading}
              handleSearchedValue={handleSearchedValue}
             
            />
            <CustomSelect
          placeholder={"Download"}
          width={"100px"}
          height={"46px"}
          options={options}
          handleChange={handleChange}
          value={"EXPORT"}
        />
          </Col>
          
          <Col className="action-sub-container">
            <Button
              size="large"
              className="create-order-button"
              onClick={handleNavigateAddSeller}
            >
              Add seller{" "}
            </Button>
          </Col>
        </Row>
        <CustomTableWithPagination
          className="order-list-table"
          //   tableExpandable={defaultExpandable}
          tableDataSource={tableDataSource || []}
          tableColumns={columns}
          locale={{
            emptyText: isLoading ? (
              <TableSkeleton length={10} />
            ) : (
              <NoDataFound text={"No sellers found!"} />
            ),
          }}
          scroll={{ x: "100%" }}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          // itemsPerPage={sellerListData?.data?.pagination?.end_index}
          itemsPerPage={20}
          totalEntries={
            !isLoading && sellerListData?.data?.pagination?.total_entries
          }
          isLoading={tableDataSource?.length && isLoading}
        />
      </Col>
      <StatusModal
        open={showStatusModal}
        setOpen={setShowStatusModal}
        handleOk={onActivate}
        data={tableActiveData}
      />
    </>
  );
};

export default Sellers;
