import React, { useEffect, useState } from "react";
import "./UnitNameCell.css";
import {
  BeforeTaxInput,
  UnitContainer,
  UnitInput,
  UnitNameInput,
} from "../../Styled";
import {
  priceAfterTax,
  totalPrice,
} from "../../../../../../../commonFuntions/CommonUtilFunctions";
import { Col } from "antd";
import { InputSelectBeforeTax } from "../../../../../../../components/Input/InputSelectBeforeTax/InputSelectBeforeTax";

const UnitNameCell = ({
  dataSource,
  tableIndex,
  params,
  record,
  setDataSource,
}) => {
  const [unitName, setUnitName] = useState("");
  const [beforeTax, setBeforeTax] = useState("");
  const [hsn, setHsn] = useState("");
  const [pricePrefix, setPricePrefix] = useState("");
  const [selected, setSelected] = useState("");

  const handleUnitName = (value) => {
    setUnitName(value);
  };
  const handleBeforeTax = (e) => {
    setPricePrefix("");
    setSelected("");
    const value = e.target.value;
    // const value = e.target.value.split(" ");
    const updatedValue = value || "";
    // const updatedValue = value[1] || "";
    const decimalPattern = /^(?:\d+|\d+\.\d{0,4})$/;
    if (decimalPattern.test(updatedValue)) {
      setBeforeTax(updatedValue || "");
    }
    if (updatedValue === "") {
      setBeforeTax("");
    }
  };

  const handleHsn = (e) => {
    const value = e.target.value.split(" ");
    const updatedValue = value[2] || "";

    setHsn(updatedValue);
  };
  const handleSave = (check, check2, price) => {
    const samePreviusValue =
      Number(record?.before_tax || 0) === Number(beforeTax || 0);
    const afterTax = priceAfterTax({
      gst: record?.gst,
      before_tax: check2 === "select_tax" ? price : beforeTax,
    });
    const total = totalPrice({
      gst: record?.gst,
      before_tax: check2 === "select_tax" ? price : beforeTax,
      discount: check === "before_tax" ? 0 : record?.discount,
      quantity: record?.quantity,
    });
    const bmp =
      check === "before_tax" && !samePreviusValue
        ? record?.amount_in === "amount"
          ? parseFloat(afterTax || 0) * parseFloat(record?.quantity || 0)
          : (parseFloat(afterTax || 0) * parseFloat(record?.quantity || 0)) /
            (1 + parseFloat(0) / 100)
        : record?.amount_in === "amount"
        ? parseFloat(afterTax || 0) * parseFloat(record?.quantity || 0) -
          parseFloat(record?.amount || 0)
        : (parseFloat(afterTax || 0) * parseFloat(record?.quantity || 0)) /
          (1 + parseFloat(record?.amount || 0) / 100);

    // const bmp =
    //   check === "before_tax"
    //     ? 0
    //     : record.amount_in === "amount"
    //     ? parseFloat(total) - parseFloat(record.amount || 0)
    //     : parseFloat(total) / (1 + parseFloat(record.amount || 0) / 100);
    setDataSource((prevData) => {
      return prevData?.map((table, index) => {
        return tableIndex === index
          ? table?.map((item) => {
              if (item?.key === record?.key) {
                return {
                  ...item,
                  unit_name: unitName,
                  before_tax: check2 === "select_tax" ? price : beforeTax,
                  hsn_sac: hsn,
                  after_tax: afterTax,
                  total: total,
                  bmp_price: bmp,
                  ...(check === "before_tax" && {
                    discount: samePreviusValue ? record?.discount : 0,
                    amount: samePreviusValue ? record?.amount : 0,
                  }),
                };
              }
              return item;
            })
          : table;
      });
    });
  };
  useEffect(() => {
    setUnitName(record?.unit_name);
    setBeforeTax(parseFloat(record?.before_tax || 0).toFixed(4));
    setHsn(record?.hsn_sac);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);
  useEffect(() => {
    if (record?.mob_sku) {
      const tolerance = 0.0001;
      const vendorSellingPrice = parseFloat(record?.vendor_selling_price || 0);
      const afterTax = parseFloat(record?.before_tax || 0);
      const arePricesEqual =
        Math.abs(vendorSellingPrice - afterTax) < tolerance;
      if (arePricesEqual) {
        setSelected(0);
        setPricePrefix("Selling Price");
      } else {
        setSelected(1);
        setPricePrefix("RFQ");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.mob_sku]);
  return (
    <>
      <UnitContainer>
        <UnitNameInput
          className="custum_input"
          placeholder="Enter Unit"
          value={unitName}
          onChange={(e) => handleUnitName(e.target.value)}
          onPressEnter={handleSave}
          onBlur={handleSave}
        />
        {record?.mob_sku ? (
          <Col style={{ width: "50%", height: "32px" }}>
            <InputSelectBeforeTax
              value={`${beforeTax ? beforeTax : ""}`}
              // value={`₹ ${beforeTax ? beforeTax : ""}`}
              handleBeforeTax={handleBeforeTax}
              handleSave={handleSave}
              record={record}
              setPricePrefix={setPricePrefix}
              pricePrefix={pricePrefix}
              setSelected={setSelected}
              selected={selected}
            />
          </Col>
        ) : (
          <BeforeTaxInput
            className="custum_input before_tax_input"
            value={`${beforeTax ? beforeTax : ""}`}
            // value={`₹ ${beforeTax ? beforeTax : ""}`}
            onChange={handleBeforeTax}
            onPressEnter={() => handleSave("before_tax")}
            onBlur={() => handleSave("before_tax")}
            prefix={"₹"}
          />
        )}
      </UnitContainer>
      <UnitInput
        value={`HSN Code: ${hsn ? hsn : ""}`}
        onChange={handleHsn}
        onPressEnter={handleSave}
        onBlur={handleSave}
      />
    </>
  );
};
export default UnitNameCell;
