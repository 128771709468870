import React, { useState } from "react";
import {
  Col,
  Typography,
  Avatar,
  Image as AntImage,
  Dropdown,
  Menu,
  Modal,
  
} from "antd";
import NoDataFound from "../../../components/EmptyTable/NoDataFound";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import { DashOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import EditProjectAddressModal from "./EditProjectAddressModal";
import {

  getNameInitials,

} from "../../../commonFuntions/CommonUtilFunctions";

const ProjectGridView = ({
  projectData,
  isFetching,
  currPageToShow,
  setCurrPageToShow,
  setIsModalVisible,
  isModalVisible,
  footer,
  handleUserAddressCreation,
  setuserAddressDetails,
  userAddressDetails,
  isSubmitClick,
  handleCancel,
  EditAddressDetails,
  setFile,
  setFileSize,
  isMobile,
  cityDisabled,
  setcityDisabled,
  setstateDisabled,
  stateDisabled,
  file,
  seller_id
}) => {
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  const { Text } = Typography;
  const columns = [
    // {
    //   key: "icon",
    //   dataIndex: "icon",
    //   // fixed: "left",
    //   width: "10px",
    //   render: (params, record) => {
    //     return (
    //       <>
            
    //       </>
    //     );
    //   },
    // },
    {
      title: "Project name",
      // dataIndex: "project_name",
      width: "250px",
      key: "project_name",
      render: (params, record) => {
        return (
          <>
          <Col className="d-flex" style={{gap:"16px"}}>
          <Dropdown
              overlay={
                <Menu>
                  <>
                    <Menu.Item
                      onClick={() => {
                        EditAddressDetails(record);
                        setIsModalVisible(true);
                      }}
                      style={{ padding: "7px 12px" }}
                    >
                      {/* <Link
                    to={`/admin/sellers/add-seller/${record?.bmp_id}`}
                    target="_blank"
                  > */}
                      <Col className="container-list">
                        <Text className="select-text">Edit</Text>
                      </Col>
                      {/* </Link> */}
                    </Menu.Item>
                  </>
                </Menu>
              }
              trigger={"click"}
            >
              <DashOutlined
              //   onClick={() => _onRowClick(record)}
              />
            </Dropdown>
            <Col className="project-icon-cell">
              {params?.project_image !== null ? (
                <AntImage
                  src={params?.project_image}
                  className="project-icon-image"
                  width={48}
                  height={48}
                />
              ) : (
                <Avatar className="project-header-avatar" size="large">
                          {!!params?.project_name &&  getNameInitials(params?.project_name)}
                </Avatar>
              )}
              <Text>{params?.project_name}</Text>
            </Col>
            </Col>
          </>
        );
      },
    },
    {
      title: "MOB Project ID",
      dataIndex: "project_id",
      key: "project_id",
      width: "150px",
    },
    {
      title: "Delivery address",
      width: "250px",
      dataIndex: "site_delivery",
      key: "site_delivery",
      render: (params, record) => {
        return (
          <p>
          {params?.name}
          <br />
          {params?.address_line_1 && params.address_line_1.trim() && (
            <>
              {params.address_line_1}
              <br />
            </>
          )}
          {params?.address_line_2 && params.address_line_2.trim() && (
            <>
              {params.address_line_2}
              <br />
            </>
          )}
          {params?.city} {params?.state}
          {"-"}
          {params?.pincode}
        </p>
        );
      },
    },
    {
      title: "Site person name",
      dataIndex: "site_delivery",
      width: "150px",
      key: "site_person",
      render: (params, record) => {
        return <Text>{params?.site_person}</Text>;
      },
    },
    {
      title: "Ph no",
      width: "150px",
      dataIndex: "site_delivery",
      key: "phone_number",
      render: (params, record) => {
        return <Text>{params?.site_person_mobile}</Text>;
      },
    },
    {
      title: "Orders",
      width: "50px",
      dataIndex: "total_orders",
      key: "total_orders",
      align: "right",
      render: (params, record) => {
        return <Text>
           <Link to={`/admin/customer/customerDetails/customerOrders/${seller_id}?project_name=${encodeURIComponent(record?.project_name)}`} className="hyperlink-text d-flex gap-8 justify-content-center">
           {params}
           </Link></Text>;
      },
    },
    {
      title: "RFQ’s",
      width: "50px",
      dataIndex: "total_rfq",
      key: "total_rfq",
      align: "right",
      render: (params, record) => {

        return <Text><Link  to={`/admin/customer/customerDetails/customerQuotation/${seller_id}?project_name=${encodeURIComponent(record?.project_name)}`} className="hyperlink-text d-flex gap-8 justify-content-center">{params}</Link></Text>;
      },
    },
    {
      width: "50px",
      title: "Google map view",
      align: "center",
      dataIndex: "site_delivery",
      key: "google_map_link",
      render: (params, record) => {
        return (
          <Link
            className="hyperlink-text"
            href={params?.google_map_link || "#"} // Fallback to '#' if link is not available
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              if (!params?.google_map_link) {
                e.preventDefault(); // Prevent the default action of the link
                EditAddressDetails(record);
                setIsModalVisible(true);
              }
            }}
          >
            {params?.google_map_link ? "Map location" : "Add map link"}
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Col className={"mt-4 width-100"}>
        <>
          <CustomTableWithPagination
            className="project-list-table "
            tableDataSource={projectData?.results}
            data={projectData}
            tableColumns={columns}
            scroll={{ x: "100%" }}
            itemsPerPage={30}
            totalEntries={!isFetching && projectData?.pagination?.total_entries}
            currentPage={currentPage}
            isLoading={projectData?.length && isFetching}
            locale={{
              emptyText: isFetching ? (
                <TableSkeleton length={30} />
              ) : (
                <NoDataFound text={"No orders found!"} />
              ),
            }}
          />
        </>
      </Col>

      <Modal
        title="Edit project details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={footer}
        className="editAddressInfoModalParent"
      >
        <EditProjectAddressModal
          // isMobile={isMobile}
          IsModalVisible={isModalVisible}
          setuserAddressDetails={setuserAddressDetails}
          userAddressDetails={userAddressDetails}
          handleUserAddressCreation={handleUserAddressCreation}
          handleCancel={handleCancel}
          setFile={setFile}
          setFileSize={setFileSize}
          file={file}
          // addAddressFlag={addAddressFlag}
          // SetaddAddressFlag={SetaddAddressFlag}
          isSubmitClick={isSubmitClick}
          // creatingAddress={creatingAddress}
          // setAddressType={setAddressType}
          cityDisabled={cityDisabled}
          setcityDisabled={setcityDisabled}
          stateDisabled={stateDisabled}
          setstateDisabled={setstateDisabled}
        />
      </Modal>
    </>
  );
};

export default ProjectGridView;
