import React, { useEffect, useState } from "react";
import "./CreateOrderProfileSection.css";
import {
  Button,
  //  Card,
  Col,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clock.svg";
import { ReactComponent as TableInfo } from "../../../../assets/icons/TableInfo.svg";
import { ReactComponent as MobCreditWhiteIcon } from "../../../../assets/icons/mobCreditWhiteIcon.svg";
import ReportingCardVertical from '../../../../components/Reporting/ReportingCardVertical';

// import { ReactComponent as SearchIcon } from "../../../../assets/icons/search-3.svg";
// import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";
import {
  ActivityContainer,
  ActivityText,
  AddressCard,
  AddressContainer,
  CardCheckbox,
  CardContainer,
  CardContent,
  CardHeader,
  CardHeaderText,
  ContentText,
  GST_TEXT,
  QuoteHeader,
  // RFQDate,
  // RFQDetailContainer,
  // RFQFileContainer,
  // RFQFileText,
  // RFQHeading,
  // RFQID,
  // RFQMainContainer,
  // RFQTag,
  // RowContainer,
} from "../../../Request/RFQ/CreateQuote/CreateQuoteProfileSection/ProfileStyled";
import CustomProfileSection from "../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
// import { colorMapping } from "../../../../commonFuntions/CommonUtilFunctions";
import EditAddressModal from "../../../../components/Modal/EditAddressModal/EditAddressModal";
import {
  addressFlagSelector,
  billingAddressSelector,
  deliveryAddressSelector,
} from "../../../../redux/slices/createQuote/selector";
import { useDispatch, useSelector } from "react-redux";
// import CreateOrderDrawer from "../../../../components/Drawer/CreateOrderDrawer/CreateOrderDrawer";
import SearchSuggestionInput from "../../../../components/Input/SearchSuggestionInput/SearchSuggestionInput";
import {
  useCreateNewUserMutation,
  useLazyGetCreateOrderUsersQuery,
  useLazyGetEditOrderAddressQuery,
  
} from "../../../../apis/createOrder";
import {
  getBillingAddress,
  getDeliveryAddress,
  getRFQUserById,
  updateAddressFlag,
} from "../../../../redux/slices/createQuote/action";
// import { useNavigate } from "react-router-dom";
// import CustomRFQSection from "../../../../components/CustomContainer/CustomRFQSection/CustomRFQSection";
import AddNewCustomerModal from "../../../../components/Modal/AddNewCustomerModal/AddNewCustomerModal";
import ActivityLogSidebar from "../../../Request/RFQ/CreateQuote/ActivityLogSidebar";
import { rupifiStatusCheck } from "../../../../commonUtils/commonUtils";

const CreateOrderProfileSection = (props) => {
  // const navigate = useNavigate();
  const {
    editCondition,
    editData,
    quoteData,
    handleSetSelector,
    userData,
    editQuoteData,
    cancelOrder,
    handleCreateOrder,
    loading,
    reportingManager,
    reportingManagers,
    setReportingManager,
    handleMenuClick,
    handleOpenChange,
    setOpen
  } = props;
  const dispatch = useDispatch();
  const delivery_address = useSelector(deliveryAddressSelector);
  const addressFlag = useSelector(addressFlagSelector);

  const billing_address = useSelector(billingAddressSelector);
  const { Title, Text } = Typography;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [getUserApi, { data: usersList }] = useLazyGetCreateOrderUsersQuery();
  const [createNewUserAPI] = useCreateNewUserMutation();
  const [getEditOrderAddressAPI, { data: addressListData }] =
    useLazyGetEditOrderAddressQuery();

  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const handleSaveBillingAddress = (e) => {
    const checked = e.target.checked;
    if (checked) {
      dispatch(getBillingAddress(delivery_address));
    } else {
      dispatch(getBillingAddress({}));
    }
  };
  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const handleShowAddCustomerModal = () => {
    setAddCustomerModal(true);
  };
  const handleRemoveSelector = () => {
    handleSetSelector({});
    dispatch(getBillingAddress({}));
    dispatch(getDeliveryAddress({}));
  };
  const handleAddressAPI = (item) => {
    getEditOrderAddressAPI({ phone_number: item?.phone_number });
    dispatch(getRFQUserById(item));
  };

  useEffect(() => {
    if (userData?.phone_number && addressFlag) {
      getEditOrderAddressAPI({ phone_number: userData?.phone_number });
      dispatch(updateAddressFlag(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFlag]);
  useEffect(() => {
    if (
      userData?.rupifiDetails?.account_status === "ACTIVE" &&
      addressListData?.data?.length
    ) {
      let mob_credit_address_data = addressListData?.data?.filter(
        (item) => item?.mob_credit
      );
      dispatch(
        getBillingAddress(
          mob_credit_address_data?.length ? mob_credit_address_data[0] : {}
        )
      );
    } else {
      dispatch(getBillingAddress({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressListData, userData]);






  // const handleCreateNewUser = () => {};
  return (
    <>
      <Row style={{ gap: "91px", marginBottom: "48px" }}>
        <QuoteHeader>
          <Title level={3} style={{ margin: 0, color: "#0a243f" }}>
            {editCondition ? "Update order" : "Create order"}
          </Title>
          <ReportingCardVertical
            isLoading = {quoteData?.isLoading}
            handleMenuClick = {handleMenuClick}
            handleOpenChange = {handleOpenChange}
            reportingManagers = {reportingManagers}
            reportingManager = {reportingManager}
            setReportingManager = {setReportingManager}
            setOpen = {setOpen}
          />
          {editCondition && (
            
            <>
            
         
              <ActivityContainer onClick={showDrawer}>
                <ClockIcon style={{ height: "20px", width: "20px" }} />
                <ActivityText level={5}>Activity log</ActivityText>
              </ActivityContainer>
            </>
          )}
        </QuoteHeader>
        <Col className="input-actions-container">
          <Col>
            {editData?.isLoading || quoteData?.isLoading ? (
              <Col className="skeleton-input">
                <Skeleton.Input active size={"small"} block={true} />
              </Col>
            ) : (
              <>
                <SearchSuggestionInput
                  size={"large"}
                  placeholder={"Type here..."}
                  width={"400px"}
                  getLibraryApi={getUserApi}
                  list={usersList?.data}
                  // handleSearchedValue = {}
                  editValue={editQuoteData?.user?.full_name}
                  disabled={editCondition}
                  handleSetSelector={handleSetSelector}
                  showModal={handleShowAddCustomerModal}
                  editCondition={editCondition}
                  handleRemoveSelector={handleRemoveSelector}
                  addressApi={handleAddressAPI}
                />
              </>
            )}
          </Col>
          <Col style={{ display: "flex", gap: "1rem" }}>
            <Button
              className="create-order-cancel-button"
              onClick={() => cancelOrder(editCondition ? "updateOrder" : "")}
            >
              CANCEL
            </Button>
            <Button
              className="create-order-confirm-button"
              onClick={handleCreateOrder}
              disabled={
                editCondition || loading
                  ? !editQuoteData?.id || loading
                  : !userData?.id || loading
              }
              loading={loading}
            >
              {editCondition ? "UPDATE ORDER" : "CONFIRM ORDER"}
            </Button>
          </Col>
        </Col>
      </Row>
      <Row style={{ gap: "48px" }}>
        <CustomProfileSection
          isLoading={editData?.isLoading || quoteData?.isLoading}
          editCondition={editCondition}
          data={editCondition ? editQuoteData?.user : userData}
          check={"editOrder"}
          // data={editCondition ? editQuoteData : selector}
        />
        {rupifiStatusCheck.includes(
          userData?.rupifiDetails?.account_status ||
            editQuoteData?.user_details?.rupifiDetails?.account_status ||
            editQuoteData?.user?.rupifiDetails?.account_status
        ) && (
          <>
            <Col
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "16px",
                border: "solid 1px rgba(0, 0, 0, 0)",
                backgroundImage:
                  (userData?.rupifiDetails?.account_status ||
                    editQuoteData?.user_details?.rupifiDetails
                      ?.account_status ||
                    editQuoteData?.user?.rupifiDetails?.account_status) ===
                    "ACTIVE" && billing_address?.mob_credit
                    ? "linear-gradient(95deg, #e8f6c6 25%, #d1f4fa 65%)"
                    : "",
                backgroundColor:
                  (userData?.rupifiDetails?.account_status ||
                    editQuoteData?.user_details?.rupifiDetails
                      ?.account_status ||
                    editQuoteData?.user?.rupifiDetails?.account_status) ===
                    "ACTIVE" && billing_address?.mob_credit
                    ? "transparent"
                    : "#ffc5b7",
                padding: "0px 16px",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 500,
                color: "#1e1e20",
              }}
            >
              {(userData?.rupifiDetails?.account_status ||
                editQuoteData?.user_details?.rupifiDetails?.account_status ||
                editQuoteData?.user?.rupifiDetails?.account_status) === "ACTIVE"
                ? "For mobCREDIT to be active during checkout please select the registered RUPIFI billing address"
                : "mobCREDIT account is not active for this user."}
            </Col>
          </>
          // ) : (
          //   <>
          //     <Col
          //       style={{
          //         width: "100%",
          //         height: "50px",
          //         borderRadius: "16px",

          //         background: "#ffc5b7",
          //         padding: "0px 16px",
          //         display: "flex",
          //         alignItems: "center",
          //         fontSize: "14px",
          //         fontWeight: 500,
          //         color: "#1e1e20",
          //       }}
          //     ></Col>
          //   </>
        )}
        <AddressContainer>
          {editData?.isLoading || quoteData?.isLoading ? (
            <>
              <Skeleton
                active
                block
                size={"large"}
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#fff",
                }}
              />
            </>
          ) : (
            <AddressCard bordered={false}>
              <CardContainer>
                <CardHeader>
                  <Col
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CardHeaderText level={4}>Delivery address</CardHeaderText>
                    {!delivery_address?.id && <TableInfo />}
                  </Col>
                  <EditAddressModal
                    address={"Delivery"}
                    editState={editCondition}
                    addressID={delivery_address?.id}
                    deliveryAddress={
                      editCondition
                        ? editQuoteData?.all_address
                        : addressListData?.data
                    }
                    // deliveryAddress={
                    //   editCondition
                    //     ? editQuoteData?.all_address?.delivery_address
                    //     : address?.delivery_address
                    // }
                  />
                </CardHeader>
                {delivery_address?.name ? (
                  <>
                    <CardContent>
                      <ContentText>{delivery_address?.name}</ContentText>

                      <ContentText>
                        {delivery_address?.address_line_1},
                        {delivery_address?.address_line_2 && (
                          <> {delivery_address?.address_line_2}, </>
                        )}
                        {delivery_address?.state},{delivery_address?.city},{" "}
                        {delivery_address?.pincode}
                      </ContentText>
                      <ContentText>
                        +91 {delivery_address?.phone_number}
                      </ContentText>
                      <Col span={24}>
                              <ContentText className="card-project-text project-tag"> Project: {delivery_address?.project ? delivery_address?.project?.project_name : <span className="text-danger-na">NA</span>}</ContentText>
                              </Col>
                    </CardContent>
                    <Col>
                      <CardCheckbox onClick={handleSaveBillingAddress}>
                        Billing address same as delivery address
                      </CardCheckbox>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}
                      >
                        No address added
                      </Text>
                    </Col>
                  </>
                )}
              </CardContainer>
            </AddressCard>
          )}
          {editData?.isLoading || quoteData?.isLoading ? (
            <>
              <Skeleton
                active
                block
                size={"large"}
                style={{
                  width: "50%",
                  padding: "20px",
                  backgroundColor: "#fff",
                }}
              />
            </>
          ) : (
            <AddressCard bordered={false}>
              <CardContainer>
                <CardHeader>
                  <Col
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CardHeaderText level={4}>Billing address</CardHeaderText>
                    {!billing_address?.id && <TableInfo />}
                  </Col>
                  <EditAddressModal
                    address={"Billing"}
                    addressID={billing_address?.id}
                    deliveryAddress={
                      editCondition
                        ? editQuoteData?.all_address
                        : addressListData?.data
                    }
                    mob_credit_check={
                      userData?.rupifiDetails?.account_status ||
                      editQuoteData?.user_details?.rupifiDetails
                        ?.account_status ||
                      editQuoteData?.user?.rupifiDetails?.account_status
                    }
                    // deliveryAddress={
                    //   editCondition
                    //     ? editQuoteData?.all_address?.billing_address
                    //     : address?.billing_address
                    // }
                  />
                </CardHeader>
                {billing_address?.name ? (
                  <>
                    <CardContent>
                      <ContentText style={{ display: "flex", gap: "5px" }}>
                        {billing_address?.name}{" "}
                        {billing_address?.mob_credit && <MobCreditWhiteIcon />}
                      </ContentText>
                      {billing_address?.gst_number && (
                        <Col style={{ margin: "12px 0" }}>
                          <GST_TEXT>
                            GST NO: {billing_address?.gst_number}
                          </GST_TEXT>
                        </Col>
                      )}
                      <ContentText>
                        {billing_address?.address_line_1} ,{" "}
                        {billing_address?.address_line_2 && (
                          <>{billing_address?.address_line_2} , </>
                        )}
                        {billing_address?.state},{billing_address?.city}
                        {billing_address?.pincode}
                      </ContentText>
                      <ContentText>
                        +91 {billing_address?.phone_number}
                      </ContentText>
                      <Col span={24}>
                              <ContentText className="card-project-text project-tag"> Project: {billing_address?.project ? billing_address?.project?.project_name : <span className="text-danger-na">NA</span>}</ContentText>
                              </Col>
                    </CardContent>
                  </>
                ) : (
                  <>
                    <Col>
                      <Text
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#0a243f",
                        }}
                      >
                        No address added
                      </Text>
                    </Col>
                  </>
                )}
              </CardContainer>
            </AddressCard>
          )}
        </AddressContainer>
        {/* <Col style={{ width: "100%" }}>
          <CustomRFQSection
            title={"RFQ details"}
            isLoading={editData?.isLoading || quoteData?.isLoading}
            editCondition={editCondition}
            data={userData}
            // RFQ_ID={userId}
          />
        </Col> */}
      </Row>
      {/* <CreateOrderDrawer
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
      /> */}

      <ActivityLogSidebar
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        data={editQuoteData?.activity_logs}
      />
      <AddNewCustomerModal
        addCustomerModal={addCustomerModal}
        setAddCustomerModal={setAddCustomerModal}
        handleApi={createNewUserAPI}
        // setIsModalOpen={setIsModalOpen}
        // editData={editDataVal}
        // initialFormData={initialFormData}
        // formData={formData}
        // setFormData={setFormData}
        // addressListModal={showModal}
      />
    </>
  );
};

export default CreateOrderProfileSection;
